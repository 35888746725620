export const GENERIC_ERROR = 'There was an error processing your request, please try again later.';

export const exceptionMessageMap = {
  signIn: {
    NotAuthorizedException: {
      position: 'password',
      message: 'Incorrect username or password.',
    },
  },
  signUp: {
    UsernameExistsException: {
      position: 'email',
      message: 'An account with the given email already exists.',
    },
    InvalidPasswordException: {
      position: 'password',
      message: 'Password did not conform with policy.',
    },
    InvalidParameterException: {
      position: 'password',
      message: 'There was an invalid parameter, please try again.',
    },
    UserLambdaValidationException: {
      position: 'email',
      message: 'An account with the given email already exists.'
    }
  },
  forgotPassword: {
    LimitExceededException: {
      position: 'email',
      message: 'Attempt limit exceeded, please try after some time.'
    },
  },
  newPassword: {
    CodeMismatchException: {
      position: 'code',
      message: 'Invalid verification code provided, please try again.',
    },
    ExpiredCodeException: {
      position: 'code',
      message: 'Invalid code provided, please request a code again.',
    },
    InvalidPasswordException: {
      position: 'confirmPassword',
      message: 'Password does not conform to policy.',
    },
    InvalidParameterException: {
      position: 'confirmPassword',
      message: 'There was an invalid parameter, please try again.',
    },
    LimitExceededException: {
      position: 'confirmPassword',
      message: 'Attempt limit exceeded, please try after some time.',
    },
  },
  emailVerification: {
    CodeMismatchException: {
      position: 'code',
      message: 'Invalid verification code provided, please try again.',
    },
    ExpiredCodeException: {
      position: 'code',
      message: 'Invalid code provided, please request a code again.',
    }
  }
};

export const getExceptionMessage = (errorCode, label, defaultPosition, translationsMap) => {
  // Try to find existing message and position in map, if error code is not found message will default to generic error
  let message = exceptionMessageMap[label]?.[errorCode]?.message || GENERIC_ERROR;
  const position = exceptionMessageMap[label]?.[errorCode]?.position;
  if (translationsMap) {
    message = translationsMap[label]?.[errorCode]?.message || translationsMap?.genericError?.message || GENERIC_ERROR;
  }

  return {
    message: message,
    position: position || defaultPosition,
  };
};
