/************************************************************
 * Modal Tracking
 ************************************************************/
const MODAL_TRACKING = {
  EVENT_NAME: 'e_modalAction',
  CATEGORY_NAME: 'modal action',

  ACTION_MODAL_OPEN: 'modal open',
  ACTION_BUTTON_CLICK_LOCAL: 'button click - local sign in',
  ACTION_BUTTON_CLICK_APPLE: 'button click - apple sign in',
  ACTION_BUTTON_CLICK_GOOGLE: 'button click - google sign in',
  ACTION_ENTRY_ERROR: 'entry error',
  ACTION_VERIFY_CODE: 'verify code',

  LABEL_ACCOUNT_SIGN_IN: 'account - sign in',
  LABEL_ACCOUNT_CREATE: 'account - create',
  LABEL_ACCOUNT_RESET_PASSWORD: 'account - reset password',
  LABEL_ACCOUNT_NORMAL_VERIFY: 'account - normal verify',
};

const modalEvent = {
  eventName: MODAL_TRACKING.EVENT_NAME,
  category: MODAL_TRACKING.CATEGORY_NAME
};

/************************************************************
 * Modal Tracking Impressions
 ************************************************************/
export const modalOpenSignUp = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_MODAL_OPEN,
  label: MODAL_TRACKING.LABEL_ACCOUNT_CREATE,
};

export const modalOpenSignIn = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_MODAL_OPEN,
  label: MODAL_TRACKING.LABEL_ACCOUNT_SIGN_IN,
};

export const modalOpenNewAccountTab = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_MODAL_OPEN,
  label: MODAL_TRACKING.LABEL_ACCOUNT_CREATE,
};

export const modalOpenSignInTab = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_MODAL_OPEN,
  label: MODAL_TRACKING.LABEL_ACCOUNT_SIGN_IN,
};

export const modalOpenForgotPassword = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_MODAL_OPEN,
  label: MODAL_TRACKING.LABEL_ACCOUNT_RESET_PASSWORD,
};

export const modalOpenNormalVerify = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_MODAL_OPEN,
  label: MODAL_TRACKING.LABEL_ACCOUNT_NORMAL_VERIFY,
};

/************************************************************
 * Modal Tracking Clicks
 ************************************************************/
export const modalButtonSignUp = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_BUTTON_CLICK_LOCAL,
  label: MODAL_TRACKING.LABEL_ACCOUNT_CREATE,
};

export const modalButtonSignUpApple = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_BUTTON_CLICK_APPLE,
  label: MODAL_TRACKING.LABEL_ACCOUNT_CREATE,
};

export const modalButtonSignUpGoogle = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_BUTTON_CLICK_GOOGLE,
  label: MODAL_TRACKING.LABEL_ACCOUNT_CREATE,
};

export const modalButtonSignIn = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_BUTTON_CLICK_LOCAL,
  label: MODAL_TRACKING.LABEL_ACCOUNT_SIGN_IN,
};

export const modalButtonSignInApple = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_BUTTON_CLICK_APPLE,
  label: MODAL_TRACKING.LABEL_ACCOUNT_SIGN_IN,
};

export const modalButtonSignInGoogle = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_BUTTON_CLICK_GOOGLE,
  label: MODAL_TRACKING.LABEL_ACCOUNT_SIGN_IN,
};

export const modalButtonNormalVerify = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_VERIFY_CODE,
  label: MODAL_TRACKING.LABEL_ACCOUNT_NORMAL_VERIFY,
};


/************************************************************
 * Modal Tracking Errors
 ************************************************************/
export const modalErrorSignIn = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_ENTRY_ERROR,
  label: MODAL_TRACKING.LABEL_ACCOUNT_SIGN_IN,
};

export const modalErrorSignUp = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_ENTRY_ERROR,
  label: MODAL_TRACKING.LABEL_ACCOUNT_CREATE,
};

export const modalErrorForgotPassword = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_ENTRY_ERROR,
  label: MODAL_TRACKING.LABEL_ACCOUNT_RESET_PASSWORD,
};

export const modalErrorNormalVerify = {
  ...modalEvent,
  action: MODAL_TRACKING.ACTION_ENTRY_ERROR,
  label: MODAL_TRACKING.LABEL_ACCOUNT_NORMAL_VERIFY,
};


/************************************************************
 * Navigation Tracking
 ************************************************************/
const NAVIGATION_TRACKING = {
  CATEGORY_NAME: 'navigation - header',

  ACTION_LOG_IN: 'log in',
  ACTION_SIGN_UP: 'sign up',

  LABEL_LOG_IN: 'log in',
  LABEL_SIGN_UP: 'sign up',
};

const navigationEvent = {
  // Note: If eventName is omitted or assigned a falsy value, the default event 'e_genericEvent' will be used.
  eventName: null,
  category: NAVIGATION_TRACKING.CATEGORY_NAME
};

export const navigationSignIn = {
  ...navigationEvent,
  action: NAVIGATION_TRACKING.ACTION_LOG_IN,
  label: NAVIGATION_TRACKING.LABEL_LOG_IN,
};

export const navigationSignUp = {
  ...navigationEvent,
  action: NAVIGATION_TRACKING.ACTION_SIGN_UP,
  label: NAVIGATION_TRACKING.LABEL_SIGN_UP,
};


/************************************************************
 * Utils
 ************************************************************/
export const setTracking = (trackingData) => {
  window?.digitalDataBuilder?.addGenericEvent(trackingData);
};
