import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

const SuccessMessage = (props) => {
  const {title, subtitle} = props;

  return (
    <div className={'container-message-success'}>
      <h2>{title}</h2>
      <p>{subtitle}</p>
    </div>
  );
};

SuccessMessage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};
export default SuccessMessage;
