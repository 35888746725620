import { Amplify, Auth } from 'aws-amplify';

export const cognitoInit = (config) => {
  const redirectSignIn = config.oauth?.redirectSignIn || '';
  const redirectSignOut = config.oauth?.redirectSignOut || '';
  const origin = window.location.origin;
  const configOauthScopes = Array.isArray(config.oauth.scope) ? config.oauth.scope : [];
  const defaultScopes = ['email','openid', 'profile', 'aws.cognito.signin.user.admin'];
  const scopes = [...new Set([...defaultScopes, ...configOauthScopes])];

  Amplify.configure({
    Auth: {
      region: config.region,
      userPoolId: config.userPoolId,
      userPoolWebClientId: config.userPoolWebClientId,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      oauth: {
        domain: config.oauth?.domain,
        redirectSignIn: `${origin}${redirectSignIn}`,
        redirectSignOut: `${origin}${redirectSignOut}`,
        scope: scopes,
        responseType: 'code'
      }
    }
  });
};

export const signUp = async (username, password, locale) => {
  return await Auth.signUp({
    username,
    password,
    attributes: {
      locale: locale
    },
    autoSignIn: {
      enabled: true
    }
  });
};

export const signIn = async (username, password) => {
  const result = await Auth.signIn({
    username,
    password
  });

  return { username: result?.attributes?.email };
};

export const isUserFederated = (user) => {
  const identities = user.attributes?.identities;

  if (!identities) {
    return false;
  }

  const [{ providerName } = {}] = JSON.parse(identities);
  if (!providerName) {
    return false;
  }

  return providerName;
};

export const getCurrentAuthenticatedUser = async () => {
  try {
    const result = await Auth.currentAuthenticatedUser();
    return result;
  } catch (err) {
    return {};
  }
};

export const updateSocialUserLocale = async (user, locale) => {

  if (!isUserFederated(user)) {
    return false;
  }

  const userLocale = user?.attributes?.locale;

  if (userLocale === locale) {
    return false;
  }

  try {
    return await Auth.updateUserAttributes(user, { locale });
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getCurrentUser = (user) => {
  return {
    // Sign in with Cognito
    username: user?.attributes?.email ||
    // Sign in with a social provider
    user?.signInUserSession?.idToken?.payload?.email
  };
};

export const getCurrentSession = async () => {
  let result;

  try {
    result = await Auth.currentSession();
  } catch (err) {
    result = null;
  }
  return result;
};

export const signOut = async () => {
  let currentUser = await Auth.currentAuthenticatedUser();

  if (currentUser) {
    await Auth.signOut();
  }
};

export const forgotPassword = async (username) => {
  return await Auth.forgotPassword(username);
};

export const submitNewPassword = async (username, code, password) => {
  await Auth.forgotPasswordSubmit(username, code, password);
};

export const socialSignInWith = (provider) => async () => {
  try {
    const response = await Auth.federatedSignIn({
      provider,
    });

    return response;
  } catch (err) {
    return err;
  }
};

export const socialSignInWithApple = socialSignInWith('SignInWithApple');

export const socialSignInWithGoogle = socialSignInWith('Google');

export const confirmSignUp = async (username, code) => {
  await Auth.confirmSignUp(username, code);
};

export const resendSignUp = async (username) => {
  await Auth.resendSignUp(username);
};
