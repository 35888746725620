import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import {useFormContext} from 'react-hook-form';
import FormFieldHeader from '../FormFieldHeader';

const ToolSet = (props) => {

  const {question, tip, nameId, options, required} = props;
  const { register } = useFormContext();

  return (
    <fieldset className={'fieldset-funnel wrapper-question'}>
      <FormFieldHeader titleText={question} required={required} tipText={tip}/>
      <div className={'answers'}>
        {options.map((option, id) => (
          <div className={'answer-button'} key={id}>
            <input {...register(nameId , { required: required})}
              type="radio"
              name={nameId}
              id={`${nameId}-${option}`}
              value={option}/>
            <label htmlFor={`${nameId}-${option}`}>{option}</label>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

ToolSet.propTypes = {
  question: PropTypes.string.isRequired,
  tip: PropTypes.string.isRequired,
  nameId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  required: PropTypes.bool
};

export default ToolSet;
