const TTL_DAYS = 14;
const SESSION_NAME = 'dd_ses';

export const updateSession = (formProps, funnelData, lastPosition) => {
  let expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + TTL_DAYS);
  sessionStorage.setItem(SESSION_NAME, 'true');
  const sessionData = {
    formProps,
    funnelData,
    lastPosition,
    lastUpdate: new Date(),
    expiry: expiryDate.getTime()
  };
  localStorage.setItem(SESSION_NAME, JSON.stringify(sessionData));
  sessionData.isSession = true;
  return sessionData;
};
export const getSession = () => {
  let isSession = sessionStorage.getItem(SESSION_NAME);
  let session = JSON.parse(localStorage.getItem(SESSION_NAME));
  if (session) {
    if (new Date().getTime() > session.expiry) {
      localStorage.removeItem(SESSION_NAME);
      return null;
    }
    session.isSession = isSession;
  }
  return session;
};

export const endSession = () => {
  sessionStorage.removeItem(SESSION_NAME);
  return localStorage.removeItem(SESSION_NAME);
};

export const compareSessions = (initialState, sessionBoat) => {
  const initialStateKeys = Object.keys(initialState).sort();
  const select = initialStateKeys.reduce((r, e) => Object.assign(r, sessionBoat[e] ?
    {[e]: sessionBoat[e]} : null), {});
  if (initialStateKeys.length !== Object.keys(select).length) {
    endSession();
    return true;
  }
  return false;
};
