import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import without from 'lodash/without';
import classNames from 'classnames';
import validateWith from './validateWith';
import useForm from '../../common/hooks/useForm';
import ValidationErrorList from '../ValidationErrorList';
import ClientValidationErrorList from '../ClientValidationErrorList';
import ToolTip from '../ToolTip';
import fieldHasError from '../../common/util/fieldHasError';
import SocialSignin from '../SocialSignin';
import { setTracking, modalButtonSignUp, modalButtonSignUpApple, modalButtonSignUpGoogle } from '../../common/util/dataLayer';
import '../../common/Form.common.css';
import './SignupForm.css';

const SignupForm = (props) => {

  const { handleChange, handleSubmit, values, allErrors, clientErrors, serverErrors } = useForm(
    props.submitForm,
    validateWith,
    props.clientErrorMessages,
    {
      email: {
        defaultValue: '',
        serverErrors: props.serverErrors.email,
      },
      password: {
        defaultValue: '',
        serverErrors: props.serverErrors.password,
      },
    },
  );

  const handleSignUpClick = () => {
    setTracking(modalButtonSignUp);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="user-form-message signup-form-message">
          <pre>
            { props.text.signupMessage }&nbsp;
            <ToolTip tipText={props.text.signupMessageToolTip} position="bottom">
              <span tabIndex="103" className="link-button">
                {props.text.signupMessageCTA}
              </span>
            </ToolTip>
          </pre>
        </div>
        <fieldset
          className={
            classNames('user-form-input', {'user-form-error': fieldHasError(allErrors, 'email')})
          }
        >
          <label htmlFor="signup-email">
            { props.text.email }
          </label>
          <input
            id="signup-email"
            autoComplete="email"
            type="email"
            name="email"
            tabIndex="100"
            data-testid="signup-email"
            data-e2e="user-signup-email"
            placeholder={props.text.emailPlaceholder}
            value={values.email}
            onChange={handleChange}
          />
          {/* TODO: eliminate the need to set this defualt value with get() everywhere: */}
          <ValidationErrorList errors={get(allErrors, 'email', {})}/>
        </fieldset>
        <fieldset
          className={
            classNames('user-form-input', {'user-form-error': fieldHasError(allErrors, 'password')})
          }
        >
          <label htmlFor="signup-password">
            { props.text.password }
          </label>
          <input
            id="signup-password"
            autoComplete="new-password"
            type="password"
            name="password"
            data-e2e="user-signup-password"
            tabIndex="101"
            placeholder={props.text.passwordPlaceholder}
            value={values.password}
            onChange={handleChange}
          />
          <ClientValidationErrorList
            errors={get(clientErrors, 'password', {})}
            neutralErrorMessages={without(
              Object.values(props.clientErrorMessages.password),
              props.clientErrorMessages.password.required
            )}
          />
          <ValidationErrorList errors={get(serverErrors, 'password', {})}/>
        </fieldset>
        <div className="user-form-button-container">
          <button
            data-testid="signup-button"
            data-e2e="user-signup-button"
            tabIndex="102"
            disabled={!values.email || !values.password}
            onClick={handleSignUpClick}>
            { props.text.signupButton }
          </button>
        </div>
      </form>
      {(props.options?.socialAuthentication?.apple || props.options?.socialAuthentication?.google) && <SocialSignin
        {...props.options}
        trackingSignInWithApple={modalButtonSignUpApple}
        trackingSignInWithGoogle={modalButtonSignUpGoogle}
      />}
    </div>
  );
};

SignupForm.displayName = 'Signup Form';

SignupForm.propTypes = {
  options: PropTypes.shape({
    socialAuthentication: PropTypes.shape({
      apple: PropTypes.bool,
      google: PropTypes.bool
    })
  }),
  text: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    emailPlaceholder: PropTypes.string,
    passwordPlaceholder: PropTypes.string,
    signupMessage: PropTypes.string,
    signupMessageCTA: PropTypes.string,
    signupMessageToolTip: PropTypes.string,
    signupButton: PropTypes.string
  }),
  submitForm: PropTypes.func,
  clientErrorMessages: PropTypes.shape({
    email: PropTypes.shape({
      required: PropTypes.string,
      invalid: PropTypes.string
    }),
    password: PropTypes.shape({
      required: PropTypes.string,
      length: PropTypes.string,
      digit: PropTypes.string,
      lowercase: PropTypes.string,
      uppercase: PropTypes.string
    }),
  }),
  serverErrors: PropTypes.shape({
    email: PropTypes.objectOf(PropTypes.bool),
    password: PropTypes.objectOf(PropTypes.bool)
  })
};

SignupForm.defaultProps = {
  options: {
    socialAuthentication: {
      apple: true,
      google: true
    }
  },
  text: {
    email: 'Email',
    password: 'Password',
    emailPlaceholder: 'Enter Email',
    passwordPlaceholder: 'Create Password',
    signupMessage: 'Buying or selling a boat made easy.',
    signupMessageCTA: 'Learn how.',
    signupMessageToolTip: 'Creating an account allows you to save your favorite boats, manage the sale ' +
                          'of your boat, and receive tailored recommendations.',
    signupButton: 'Submit'
  },
  // eslint-disable-next-line no-unused-vars
  submitForm: (values, clientErrors) => {},
  clientErrorMessages: {
    email: {
      required: 'Email is required',
      invalid: 'Email address is invalid'
    },
    password: {
      required: 'Password is required',
      length: 'At least 8 characters',
      digit: 'At least one number',
      lowercase: 'At least one lowercase letter',
      uppercase: 'At least one uppercase letter'
    },
  },
  serverErrors: {
    email: {},
    password: {}
  }
};

export default SignupForm;
