import { useState, useMemo } from 'react';
import mapValues from 'lodash/mapValues';
import cloneDeep from 'lodash/cloneDeep';
import mergeDeepLeft from 'ramda/src/mergeDeepLeft';
import useDidMountDeepCompareEffect from './useDidMountDeepCompareEffect';

const useForm = (submitForm, validateWith, clientErrorMessages, fieldData) => {
  const [values, setValues] = useState(
    mapValues(fieldData, x => x.defaultValue)
  );

  const [allErrors, setAllErrors] = useState(
    mapValues(fieldData, x => x.serverErrors)
  );

  const [serverErrors, setServerErrors] = useState(
    mapValues(fieldData, x => x.serverErrors)
  );

  const [clientErrors, setClientErrors] = useState({});

  const validate = useMemo(
    () => validateWith(clientErrorMessages),
    [clientErrorMessages, values]
  );

  const handleChange = e => {
    const { name, value } = e.target;

    const newValues = {
      ...values,
      [name]: value
    };

    let newServerErrors = cloneDeep(serverErrors);
    newServerErrors[name] = {};
    setServerErrors(newServerErrors);

    setValues(newValues);

    setClientErrors({
      ...clientErrors,
      [name]: validate(newValues)[name]
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    setClientErrors(validate(values));
    submitForm(values, validate(values));
  };

  useDidMountDeepCompareEffect(() => {
    setServerErrors(mapValues(fieldData, x => x.serverErrors));
  }, [mapValues(fieldData, x => x.serverErrors)]);

  useDidMountDeepCompareEffect(() => {
    setAllErrors(
      mergeDeepLeft(
        serverErrors,
        clientErrors
      )
    );
  }, [
    serverErrors,
    clientErrors
  ]);

  return {
    handleChange,
    handleSubmit,
    values,
    allErrors,
    clientErrors,
    serverErrors,
  };
};

export default useForm;
