import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const FunnelSuccessMessage = (props) => {
  return (
    <div className={'funnel-message-success'}>
      <div className={'content'}>
        <h2>{props.title}</h2>
        <p>{props.subtitle}</p>
      </div>
    </div>

  );
};

FunnelSuccessMessage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default FunnelSuccessMessage;
