import validatePassword from '../../common/util/validatePassword';
import { EMAIL_REGEX } from '../../common/Constants.common';

const validateWith = (errorMessages) => (values) => {
  // errors must be {[string]: boolean} objects
  let errors = {
    password: {},
    email: {}
  };

  if (!values.email) {
    errors.email[errorMessages.email.required] = true;
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email[errorMessages.email.invalid] = true;
  }

  if (!values.password) {
    errors.password[errorMessages.password.required] = true;
  } else {
    errors = validatePassword(values, errors, errorMessages);
  }

  return errors;
};

export default validateWith;
