import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';
import fieldHasError from '../../common/util/fieldHasError';
import useForm from '../../common/hooks/useForm';
import validateWith from './validateWith';
import ValidationErrorList from '../ValidationErrorList';
import SocialSignin from '../SocialSignin';
import { setTracking, modalButtonSignIn, modalButtonSignInApple, modalButtonSignInGoogle } from '../../common/util/dataLayer';

import '../../common/Form.common.css';
import './SigninForm.css';

const SigninForm = (props) => {
  const { handleChange, handleSubmit, values, allErrors } = useForm(
    props.submitForm,
    validateWith,
    props.clientErrorMessages,
    {
      username: {
        defaultValue: '',
        serverErrors: props.serverErrors.username,
      },
      password: {
        defaultValue: '',
        serverErrors: props.serverErrors.password,
      },
    },
  );

  const handleSignInClick = () => {
    setTracking(modalButtonSignIn);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="user-form-message">{props.text.signinMessage}</div>
        <fieldset className={classNames('user-form-input', {'user-form-error': fieldHasError(allErrors, 'username')})}>
          <label htmlFor="username">{props.text.username}</label>
          <input
            id="username"
            name="username"
            type="email"
            data-e2e="login-username"
            tabIndex="100"
            value={values.username}
            autoComplete="email"
            placeholder={props.text.usernamePlaceholder}
            onChange={handleChange}
            required
          />
          <ValidationErrorList errors={get(allErrors, 'username', {})}/>
        </fieldset>
        <fieldset className={classNames('user-form-input', {'user-form-error': fieldHasError(allErrors, 'password')})}>
          <label htmlFor="password">{props.text.password}</label>
          <input
            id="password"
            name="password"
            type="password"
            data-e2e="login-password"
            tabIndex="101"
            value={values.password}
            placeholder={props.text.passwordPlaceholder}
            onChange={handleChange}
            required
          />
          <ValidationErrorList errors={get(allErrors, 'password', {})}/>
        </fieldset>
        <div className="user-form-button-container">
          <button
            data-testid="signin-button"
            className="user-form-button"
            data-e2e="login-signin-button"
            disabled={!values.username || !values.password}
            onClick={handleSignInClick}>
            {props.text.signinButtonText}
          </button>
        </div>
        <button
          type="button"
          tabIndex="102"
          data-testid="forgot-password-button"
          className="signin-forgot-password-button"
          data-e2e="user-forgot-password-button"
          onClick={props.backFunction}>
          {props.text.forgotPasswordLink}
        </button>
      </form>
      {(props.options?.socialAuthentication?.apple || props.options?.socialAuthentication?.google) && <SocialSignin
        {...props.options}
        trackingSignInWithApple={modalButtonSignInApple}
        trackingSignInWithGoogle={modalButtonSignInGoogle}
      />}
    </div>
  );
};

SigninForm.displayName = 'Signin Form';
SigninForm.propTypes = {
  options: PropTypes.shape({
    socialAuthentication: PropTypes.shape({
      apple: PropTypes.bool,
      google: PropTypes.bool
    })
  }),
  text: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
    usernamePlaceholder: PropTypes.string,
    passwordPlaceholder: PropTypes.string,
    signinMessage: PropTypes.string,
    signinButtonText: PropTypes.string,
    forgotPasswordLink: PropTypes.string
  }),
  clientErrorMessages: PropTypes.shape({
    username: PropTypes.shape({
      required: PropTypes.string,
      invalid: PropTypes.string
    }),
    password: PropTypes.shape({
      required: PropTypes.string,
    }),
  }),
  serverErrors: PropTypes.shape({
    username: PropTypes.objectOf(PropTypes.bool),
    password: PropTypes.objectOf(PropTypes.bool)
  }),
  backFunction: PropTypes.func,
  submitForm: PropTypes.func,
};
SigninForm.defaultProps = {
  options: {
    socialAuthentication: {
      apple: true,
      google: true
    }
  },
  text: {
    username: 'Email',
    password: 'Password',
    usernamePlaceholder: 'Enter Email',
    passwordPlaceholder: 'Enter Password',
    signinMessage: 'Sign in to manage your listings and saved boats all in one place.',
    signinButtonText: 'Sign In',
    forgotPasswordLink: 'Forgot Password?'
  },
  clientErrorMessages: {
    username: {
      required: 'Email is required',
      invalid: 'Email address is invalid'
    },
    password: {
      required: 'Password not valid',
    },
  },
  serverErrors: {
    username: {},
    password: {}
  },
  backFunction: () => { },
  // eslint-disable-next-line no-unused-vars
  submitForm: (values, errors) => { }
};

export default SigninForm;
