export const ESCAPE_KEY_CODE = 27;
export const YES_VALUE = 'yes';
export const INAPPROPRIATE_ERROR_MESSAGE = 'Error: Couldn\'t upload image: inappropriate content identified.';
export const INAPPROPRIATE_ERROR_TYPE = 'inappropriate';
export const IMAGE_UPLOAD_ERROR_MESSAGE = 'Error: Couldn\'t upload image';
export const IMAGE_UPLOAD_ERROR_TYPE = 'Upload Error';
export const TEST_IMAGE_UPLOAD_ENDPOINT = 'https://driveway.qa.boattrader.com/upload';
export const TEST_LOCATIONS_ENDPOINT = 'https://driveway.qa.boattrader.com/address';
export const SENT_LEAD_VALUE = 'SENT';
export const FAILED_LEAD_VALUE = 'FAILED';
export const SENDING_LEAD_VALUE = 'SENDING';
