import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useState, useEffect, useRef } from 'react';
import ReactPinField from 'react-pin-field';
import classNames from 'classnames';
import ValidationErrorList from '../../ValidationErrorList';
import fieldHasError from '../../../common/util/fieldHasError';
import { setTracking, modalButtonNormalVerify } from '../../../common/util/dataLayer';
import '../../../common/Form.common.css';

const VerificationCodeForm = (props) => {

  const ref = useRef(null);
  const {text, submitForm, resendCodeFunction, serverErrors} = props;
  const [code, setCode] = useState('');
  const [emailVerificationErrors, setEmailVerificationErrors] = useState({code: {}});
  useEffect(() => { ref && ref.current && ref.current[0].focus(); }, []);
  useEffect(() => setEmailVerificationErrors(serverErrors), [serverErrors]);

  const handleChange = (value) => {
    setCode(value);
    setEmailVerificationErrors({code: {}});
  };

  const handleSubmit = e => {
    e.preventDefault();
    submitForm(code);
    setTracking(modalButtonNormalVerify);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="message">
          <p>{text.headerInformation.message1}</p>
        </div>
        <div className="message">
          <label htmlFor="code">{text.headerInformation.message2}</label>
        </div>
        <fieldset className={classNames('user-form-input', {'user-form-error': fieldHasError(emailVerificationErrors, 'code')})}>
          <div className={classNames('code-input', {error: Object.keys(emailVerificationErrors.code).length !== 0})}>
            <ReactPinField
              id="code"
              ref={ref}
              inputMode="numeric"
              length={6}
              validate="0123456789"
              onChange={handleChange}
              type="number"
              className="pin-field"
            />
          </div>
          <ValidationErrorList errors={get(emailVerificationErrors, 'code', {})}/>
        </fieldset>
        <div className="user-form-button-container">
          <button
            tabIndex="100"
            disabled={code.length !== 6}>
            { text.submitButton.label }
          </button>
        </div>
        <div className="message">
          <p>
            <span>{text.footerInformation.message1}</span>
            <a className="link-button" onClick={resendCodeFunction} tabIndex="101">{text.footerInformation.message2}</a>
          </p>
        </div>
      </form>
    </div>
  );
};

VerificationCodeForm.displayName = 'Verification Code Form';

VerificationCodeForm.propTypes = {
  text: PropTypes.shape({
    submitButton: PropTypes.shape({
      label: PropTypes.string
    }),
    headerInformation: PropTypes.shape({
      message1: PropTypes.string,
      message2: PropTypes.string
    }),
    footerInformation: PropTypes.shape({
      message1: PropTypes.string,
      message2: PropTypes.string
    }),
  }),
  serverErrors: PropTypes.shape({
    code: PropTypes.objectOf(PropTypes.bool)
  }),
  clientErrorMessages: PropTypes.shape({
    code: PropTypes.shape({
      required: PropTypes.string
    })
  }),
  resendCodeFunction: PropTypes.func,
  submitForm: PropTypes.func,
};

VerificationCodeForm.defaultProps = {
  text: {
    submitButton: {
      label: 'Verify'
    },
    headerInformation: {
      message1: 'If the email entered exists for an account, a verification code will be sent to that address.',
      message2: 'Please enter the code below.'
    },
    footerInformation: {
      message1: 'Did not receive the code?',
      message2: 'Resend',
    }
  },
  clientErrorMessages: {
    code: {
      required: 'Code is required',
    }
  },
  serverErrors: {
    code: {}
  },
};

export default VerificationCodeForm;
