import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

const ErrorMessages = ({errorMessages}) => {
  return (
    <ul data-testid="errors">
      {errorMessages && errorMessages.map((error, idx) => (
        <li key={idx} className={'message-error'}>{error}</li>
      ))}
    </ul>
  );
};

ErrorMessages.propTypes = {
  errorMessages: PropTypes.array
};

export default ErrorMessages;
