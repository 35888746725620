import React from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import ToolTip from '../ToolTip';

const FormFieldHeader = (props) => {
  const { titleText, tipText, required, field } = props;

  return (
    <div className="form-field-header">
      <label htmlFor={field}><span className="input-title">{titleText}{required && <span className="required-marker"> * </span>}</span></label>

      {tipText &&
        <ToolTip
          activateEvent={isMobile ? 'click' : 'hover'}
          position={isMobile ? 'bottom' : 'right'}
          tipText={tipText}
        />
      }
    </div>
  );
};

FormFieldHeader.propTypes = {
  titleText: PropTypes.string.isRequired,
  tipText: PropTypes.string,
  required: PropTypes.bool,
  field: PropTypes.string
};

FormFieldHeader.defaultProps = {
  required: true
};

export default FormFieldHeader;
