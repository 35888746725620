import { EMAIL_REGEX } from '../../common/Constants.common';

const validateWith = (errorMessages) => (values) => {
  let errors = {
    password: {},
    username: {}
  };

  if (!values.username) {
    errors.username[errorMessages.username.required] = true;
  } else if (!EMAIL_REGEX.test(values.username)) {
    errors.username[errorMessages.username.invalid] = true;
  }

  if (!values?.password) {
    errors.password[errorMessages.password.required] = true;
  }

  return errors;
};

export default validateWith;
