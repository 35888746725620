import { get, cloneDeep, set } from 'lodash';
import {YES_VALUE} from './constants';

export const encodeFields = (obj, fields) => {
  let result = cloneDeep(obj);
  fields.forEach(field => {
    const enc = encodeField(obj, field);
    set(result, field, enc);
  });
  return result;
};

const encodeField = (obj, field) => {
  field = field.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  field = field.replace(/^\./, ''); // strip a leading dot
  const toEncode = get(obj, field);
  return encodeURIComponent(toEncode);
};

export const encodeStringFields = (obj) => {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      encodeStringFields(obj[key]);
    } else if (typeof obj[key] === 'string'){
      obj[key] = encodeURIComponent(obj[key]);
    }
  });
  return obj;
};

export const splitFullName = (text) => {
  return text.replace(/^\s+|\s+$|\s+(?=\s)/g, '').split(' ');
};

export const mapFormToAPI = (funnelData, formProps) => {
  console.log('formProps - mapFormToAPI', formProps);
  const splittedFullName = splitFullName(funnelData.info.fullName);
  const firstName = splittedFullName[0];
  const lastName = getLastName(splittedFullName, firstName);
  const company = getCompanyName(firstName, lastName);
  const { city, state, zip } = mapLocationTextToDetails(funnelData.details.location);

  const formEngines = [
    funnelData.basics.engine1,
    funnelData.basics.engine2,
    funnelData.basics.engine3,
    funnelData.basics.engine4
  ].slice(0, funnelData.basics.engineCount);

  const engines = formEngines.map((formEngine) => ({
    engine: funnelData.basics.driveType,
    engineMake: formEngine.make,
    engineModel: formEngine.model,
    horsePower: formEngine.horsepower,
    engineHours: formEngine.hours
  }));

  let body = encodeStringFields({
    source: 'Boat Trader - Driveway Direct',
    salesforceId: formProps.salesforceId,
    boat: {
      make: formProps.boat.make.value,
      model: formProps.boat.model.value,
      year: formProps.boat.year.value,
      length: formProps.boat.length.value,
      hasTrailer: funnelData.basics.trailer.toLowerCase() === YES_VALUE,
      anyIssues: funnelData.details.problems,
      condition: funnelData.details.boatCondition,
      saltwaterUse: funnelData.details.saltwater.toLowerCase() === YES_VALUE,
      hasLoan: funnelData.info.loan.toLowerCase() === YES_VALUE,
      hasWarranty: funnelData.info.warranty.toLowerCase() === YES_VALUE,
      intentSell: funnelData.info.intentSell.toLowerCase() === YES_VALUE,
      engines,
      city,
      countryCode: 'US',
      countrySubDivisionCode: state,
      postalCode: zip
    },
    contact: {
      phone: funnelData.info.phone,
      zip: formProps.contact.zip.value,
      firstName,
      lastName,
      company,
      contactAllowed: formProps.contact.contactAllowed.value,
    }
  });

  body.contact.email = funnelData.info.email;

  const photos = [
    funnelData.details.backViewUrl,
    funnelData.details.cockpitViewUrl,
    funnelData.details.deckViewUrl,
    funnelData.details.enginesViewUrl,
    funnelData.details.frontViewUrl,
    funnelData.details.leftViewUrl,
    funnelData.details.rightViewUrl,
  ].filter(x => !!x);

  if (photos.length > 0) {
    body.boat.photos = photos;
  }

  return body;
};

export const isValidGeneralPhoneNumber = (num) => (num.length < 16) && (num.length > 9);

const getLastName = (splittedFullName, firstName) => {
  const fullLastName = splittedFullName.slice(1, splittedFullName.length).join(' ');
  // if the user only wrote the name or for some reason the rest of the name is an empty string
  // return the first name as last name value.
  if (splittedFullName.length === 1 || fullLastName === '') {
    return firstName;
  }
  return fullLastName;
};

const getCompanyName = (firstName, lastName) => {
  // If its a wrong lead, return the company name as wrong lead also
  if (firstName.split(' ').length > 1) {
    return firstName;
  }
  // otherwise return the clean full name
  return `${firstName} ${lastName}`;
};

export const getFormBody = (state) => {
  const boat = state.boat;
  const contact = state.contact;
  const splittedFullName = splitFullName(contact.fullName.value);
  const firstName = splittedFullName[0] || 'Wrong lead';
  const lastName = getLastName(splittedFullName, firstName);
  const company = getCompanyName(firstName, lastName);
  const bod = {
    source: 'Boat Trader - Driveway Direct',
    boat: {
      ...boat,
      year: boat.year.value,
      make: boat.make.value,
      length: boat.length.value,
      model: boat.model.value
    },
    contact: {
      phone: contact.phone.value,
      email: contact.email.value,
      zip: contact.zip.value,
      firstName,
      lastName,
      company,
      contactAllowed: contact.contactAllowed.value
    }
  };
  let tmp = encodeStringFields(bod);
  tmp.contact.email = contact.email.value;
  console.log('BOD', bod);
  return bod;
};

export const mapLocationTextToDetails = (text) => {
  const detailsArr = text.split(', ');
  return {
    zip: detailsArr[0],
    city: detailsArr[1],
    state: detailsArr[2]
  };
};
