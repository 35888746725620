import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './styles.css';
import { Carousel } from 'react-responsive-carousel';
import {
  BOATTRADER,
  BOATSDOTCOM,
  YACHTWORLD
} from '../../constants/constants';
import {heightFromWidth, SIXTEEN_NINE} from '../../utils/sizes';

const DEFAULT_HEIGHT = '150px';
const getIframes = element => [...element.getElementsByTagName('iframe')];
const widthToNumber = w => +String(w).replace('px', '');
const ifFrameWidth = iframe => widthToNumber(window.getComputedStyle(iframe).width);

const YoutubeVideos = ({videos, maxNumberOfVideos = 10, portal = BOATTRADER, device = 'mobile'}) => {
  const [iframeHeight, setIframeHeight] = useState(DEFAULT_HEIGHT);
  const videosContainerRef = useRef(null);
  useEffect(() => {
    const onResize = () => {
      if (videosContainerRef.current) {
        const iframes = getIframes(videosContainerRef.current);
        const widths = iframes.map(iframe => ifFrameWidth(iframe));
        const width = Math.max(...widths);
        const height = heightFromWidth(width, SIXTEEN_NINE);
        setIframeHeight(Math.round(height) + 'px');
      }
    };

    const onFullScreen = () => {
      // We need to resize afterwards to avoid an ugly black bar
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1);
    };
    window.addEventListener('resize', onResize);
    window.addEventListener('fullscreenchange', onFullScreen);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('fullscreenchange', onFullScreen);
    };
  }, [device]);

  const numVideos = maxNumberOfVideos;
  let centerSlidePercentage = 100;
  const urls = Array.isArray(videos) ?
    videos.map(video => `https://www.youtube.com/embed/${video}`).slice(0, numVideos) : [];
  const wrapClass = urls.length === 1 ? 'one-col-video' : 'two-cols-video';
  if (urls.length > 1) {
    centerSlidePercentage = device === 'mobile' || urls.length === 2 ? 90 : 45;
  }
  if (urls.length === 0) {
    return null;
  }

  return (
    <>
      { (urls.length > 2) &&
      <>
        <div className="srp-videos" data-test-sp="srp-videos" ref={videosContainerRef}>
          <Carousel
            centerMode
            centerSlidePercentage={centerSlidePercentage}
            infiniteLoop={false}
            showArrows={'showArrows'}
            renderIndicator={false}
            showThumbs={false}
            showStatus={false}
            emulateTouch={true}
            useKeyboardArrows={true}
            dynamicHeight={true}
          >
            { urls.map((url, i) =>
              <iframe style={{height: iframeHeight}} key={i} title="video" className="video" data-test-sp="srp-video-item" src={url} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} />
            ) }
          </Carousel>
        </div>
      </>
      }
      { (urls.length <= 2) &&
      <>
        <div className="srp-videos-without-carousel" data-test-sp="srp-videos-without-carousel" ref={videosContainerRef}>
          { urls.map((url, i) =>
            <div key={i} className={wrapClass}>
              <iframe style={{height: iframeHeight}} title="video" className="video" data-test-sp="srp-video-item" src={url} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} />
            </div>
          ) }
        </div>
      </>
      }
      <div className="partnership" data-test-sp="srp-partnership">
        <p className="message">In partnership with: </p>
        { portal !== BOATTRADER &&
          <div className="boattrader-container">
            <div className="boattrader"></div>
            <div className="separator">|</div>
          </div>
        }
        { portal !== BOATSDOTCOM &&
          <div className="boatsdotcom-container">
            <div className="boatsdotcom"></div>
            {
              portal !== YACHTWORLD &&
              <div className="separator">|</div>
            }
          </div>
        }
        { portal !== YACHTWORLD &&
          <div className="yachtworld"></div>
        }
      </div>
    </>
  );
};

YoutubeVideos.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.string),
  maxNumberOfVideos: PropTypes.number,
  device: PropTypes.string,
  portal: PropTypes.string,
  isFirstPage: PropTypes.bool
};

export default YoutubeVideos;
