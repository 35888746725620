import React, {useContext} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {AltTextContext} from '../Accordion';
import greenCheckIcon from './green-check.svg';
import circleIcon from './blue-circle.svg';
import editIcon from './edit-button.svg';
import './styles.css';

const AccordionSection = (props) => {
  const sectionClasses = classNames({
    'accordion-section': true,
    'active': props.active,
    'complete': props.complete
  });

  const altText = useContext(AltTextContext);

  return (
    <div className={sectionClasses}>
      <div className="seperator"/>
      <div className="header">
        {props.complete ? (
          <img alt={altText.completedIconAlt} src={greenCheckIcon}/>
        ) : (
          <div className="incomplete-wrapper">
            <img alt={altText.incompleteIconAlt} src={circleIcon}/>
            <div>{props.order}</div>
          </div>
        )}
        <h2>{props.title}</h2>
        {!props.active && props.complete &&
          <img alt={altText.editIconAlt}
            src={editIcon}
            className="icon-edit"
            onClick={(e) => props.editSection(e, props.section)}/>
        }
      </div>
      <div className="content-wrapper">
        {props.active && (
          props.children
        )}
      </div>
    </div>
  );
};

AccordionSection.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  complete: PropTypes.bool.isRequired,
  order: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
  editSection: PropTypes.func.isRequired,
  section: PropTypes.string.isRequired
};

export default AccordionSection;
