import React from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import FormFieldHeader from '../FormFieldHeader';
import './styles.css';

const BinaryRadio = ({ text, id, value, name, required, testId }) => {
  const { register, watch } = useFormContext();
  watch();

  return (
    <fieldset className={'fieldset-funnel'}>
      <FormFieldHeader titleText={text.title} tipText={text.tip}/>
      <div className="binary-radio-wrapper">
        <label className="binary-radio-control">
          <input
            {...register(name, {
              required,
            })}
            type="radio"
            id={id.yes}
            value={value.yes}
            data-testid={testId.yes}
          />
          {text.yes}
        </label>

        <label className="binary-radio-control">
          <input
            {...register(name, {
              required,
            })}
            type="radio"
            id={id.no}
            value={value.no}
            data-testid={testId.no}
          />
          {text.no}
        </label>
      </div>
    </fieldset>
  );
};

BinaryRadio.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  testId: PropTypes.shape({
    yes: PropTypes.string,
    no: PropTypes.string
  }),
  id: PropTypes.shape({
    yes: PropTypes.string,
    no: PropTypes.string
  }),
  text: PropTypes.shape({
    title: PropTypes.string,
    tip: PropTypes.string,
    yes: PropTypes.string,
    no: PropTypes.string
  }).isRequired,
  value: PropTypes.shape({
    yes: PropTypes.string,
    no: PropTypes.string
  }).isRequired
};

BinaryRadio.defaultProps = {
  testId: {
    yes: '',
    no: ''
  }
};

export default BinaryRadio;
