import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const Move = {
  UP: 1,
  DOWN: 2
};

export const scrollElementInContainer = (element, container, direction) => {
  if (!element || !container) {
    return;
  }
  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();
  const shouldGoUp = elementRect.top < containerRect.top;
  const shouldGoDown = elementRect.top + elementRect.height > container.offsetTop + container.offsetHeight;
  if (direction === Move.UP && shouldGoUp) {
    container.scrollTo({top: element.offsetTop});
  } else if (direction === Move.DOWN && shouldGoDown) {
    element.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
  }
};

const nil = () => {};

const SelectTypeAhead = ({
  list, originalValue = '', error = null, onChange = nil, label = '', inputId = 'sta_select',
  loading = false, defaultListOption = null, placeholderText = '', disabled = false, dataE2e = ''
}) => {
  const [value, setValue] = useState(originalValue);
  const [options, setOptions] = useState([]);
  const [displayOptions, setDisplayOptions] = useState(false);
  const [listIndex, setListIndex] = useState(0);
  const [moveDirection, setMoveDirection] = useState(Move.DOWN);
  const listElement = useRef();
  const inputElement = useRef();

  useEffect(() => {
    getOptions();
    return () => {
      getOptions([]);
    };
  }, [value]);

  useEffect(() => {
    updateScrollView(moveDirection);
  }, [listIndex]);

  useEffect(() => {
    if (displayOptions) {
      const index = options.findIndex(option => option === value);
      inputElement.current.focus();
      if (index !== listIndex) {
        setMoveDirection(Move.DOWN);
        setListIndex(index > -1 ? index : 0);
      } else {
        updateScrollView(Move.DOWN);
      }
    }
  }, [displayOptions]);

  useEffect(() => {
    if (list.includes(originalValue)) {
      selectOption(originalValue);
    } else if ( originalValue === '' ){
      setValue('');
    }
  }, [originalValue]);

  const updateScrollView = (direction) => {
    const container = listElement.current;
    const element = listIndex >= 0 && container?.children[listIndex];
    scrollElementInContainer(element, container, direction);
  };

  const getOptions = () => {
    const filteredOptions = list.filter(listValue => value && listValue.match(new RegExp(value, 'i')));
    setListIndex(0);
    // when we don't find anything, if we have defaultListOption, that option appears
    const newOptions = filteredOptions.length === 0 && defaultListOption ? [defaultListOption] : filteredOptions;
    setOptions(newOptions);
  };

  const changedValue = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  const handleKeyDown = (e) => {
    if (!displayOptions) {
      return;
    }

    switch (e.key) {
    case 'ArrowDown':
      e.preventDefault();
      if (listIndex + 1 < options.length) {
        setListIndex(listIndex + 1);
        setMoveDirection(Move.DOWN);
      }
      break;
    case 'ArrowUp':
      e.preventDefault();
      if (listIndex - 1 >= 0) {
        setListIndex(listIndex - 1);
        setMoveDirection(Move.UP);
      }
      break;
    case 'Enter':
      e.preventDefault();
      changedValue(options[listIndex]);
      break;
    case 'Escape':
      e.preventDefault();
      hideOptions();
      break;
    default:
      break;
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      hideOptions();
    }
  };

  const handleKeyChange = (target) => {
    changedValue(target.value);
    setDisplayOptions((target.value && target.value.length > 0));
  };

  const selectOption = (option) => {
    changedValue(option);
    hideOptions();
  };

  const hideOptions = () => {
    setOptions([]);
    setDisplayOptions(false);
  };

  const toggleOptions = () => {
    const visible = !displayOptions;
    setOptions(list);
    setDisplayOptions(visible);
  };
  return (
    <div className={'sta-container'}>
      {label && <label className={'sta-label'} htmlFor={inputId} id={`${inputId}-label`}>{label}</label>}
      <div className={error ? 'sta-wrapper-input error-validation' : 'sta-wrapper-input'} onClick={toggleOptions} role={'combobox'}>
        <input type={'text'}
          ref={inputElement}
          id={inputId}
          value={value}
          disabled={loading || disabled ? 'disabled' : null}
          onBlur={() => hideOptions()}
          onChange={(({target}) => handleKeyChange(target))}
          onKeyDown={(e) => handleKeyDown(e)}
          onKeyUp={(e) => handleKeyUp(e)}
          maxLength={'100'}
          required
          placeholder={placeholderText}
          autoComplete={'off'}
          aria-label={label}
          araia-aria-labelledby={`${inputId}-label`}
          data-e2e={dataE2e}
        />
      </div>
      {
        options.length > 0 && displayOptions &&
        <div className={'sta-options'} ref={listElement}>
          {options.map((option, idx) => (
            <div tabIndex="-1" role="option" aria-checked="false" className={idx === listIndex ? 'sta-option selected' : 'sta-option'}
              onMouseDown={() => selectOption(option)}
              data-e2e={`${dataE2e}-option`}
              key={idx}><span>{option}</span></div>
          ))}
        </div>
      }
    </div>
  );
};

SelectTypeAhead.propTypes = {
  list: PropTypes.array.isRequired,
  originalValue: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  inputId: PropTypes.string,
  defaultListOption: PropTypes.string,
  placeholderText: PropTypes.string,
  displayLabel: PropTypes.bool,
  dataE2e: PropTypes.string
};

export default SelectTypeAhead;
