import axios from 'axios';
import {FAILED_LEAD_VALUE, SENT_LEAD_VALUE} from './constants';
import {mapFormToAPI} from './util';

export const sendLead = async (funnelProps, formProps, endpoint) => {
  try {
    const transformedData = mapFormToAPI(funnelProps, formProps);
    console.log('transformedData', transformedData);
    await axios.post(endpoint, transformedData);
    return SENT_LEAD_VALUE;
  } catch (e) {
    return FAILED_LEAD_VALUE;
  }
};

export const getMakes = async (endpoint) => {
  try {
    const makes = await axios.get(endpoint);
    const foundMakes =  Object.keys(makes.data).map((make) => (makes.data[make].make));
    return foundMakes;
  } catch (e) {
    return [];
  }
};

export const getModels = async (make, year, endpoint) => {
  if (make.value === '' || make.value === 'custom/other' || make.error || year.value === '') {
    return [];
  }

  try {
    const url = `${endpoint}?make=${make.value}&year=${year.value}`;
    const models = await axios.get(url);
    return models.data;
  } catch (e) {
    return [];
  }
};

export const getLocations = async (text, endpoint) => {
  return axios.get(endpoint, {
    params: {
      text,
      filterCountries: 'US'
    }
  })
    .then(res => {
      return res.data.Results.map(({ Place: { Text } }) => {
        const splittedAddress = Text.split(',');
        if (splittedAddress[0].length <= 5 && !isNaN(splittedAddress[0])){
          return Text;
        }
      });
    })
    .catch(() => {
    });
};
