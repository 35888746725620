import React, {useState, useEffect, useRef} from 'react';
import './styles.css';
import PropTypes from 'prop-types';

const TypeAheadInput = (
  {list, value, error, setValue, label, inputId, loading, defaultListOption,
    placeholderText, disabled, blockNonNumeric, minSearchLength}
) => {
  const [options, setOptions] = useState([]); // change
  const [displayOptions, setDisplayOptions] = useState(false); // change
  const [listIndex, setListIndex] = useState(0);
  minSearchLength = minSearchLength || 0; // setting default value of 0 if no prop value has been provided
  const [metaKeyDown, setMetaKeyDown] = useState(false);
  const [ctrlKeyDown, setCtrlKeyDown] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    getOptions();
    return () => {
      getOptions([]);
    };
  }, [value, list]);

  const handleBlur = () => {
    setMetaKeyDown(false);
    setCtrlKeyDown(false);
  };

  useEffect(() => {
    inputRef.current.addEventListener('blur', handleBlur);
    return () => {
      document.removeEventListener('blur', handleBlur);
    };
  }, []);

  const handleOnMouseleave = () => {
    setMetaKeyDown(false);
    setCtrlKeyDown(false);
  };

  useEffect(() => {
    document.body.addEventListener('mouseleave', handleOnMouseleave);
    return () => document.removeEventListener('mouseleave', handleOnMouseleave);
  }, []);

  const getOptions = () => {
    if (value.length < minSearchLength){
      return;
    }

    let newOptions = [];

    list.forEach((listValue) => {
      if (value && listValue && listValue.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
        newOptions.push(listValue);
      }
    });

    setOptions(newOptions.length === 0 ? defaultListOption ? [defaultListOption] : [] : newOptions);
  };

  const handleKeyDown = (e) => {
    // if the key wasn't a digit or the length isn't greater than one and it the
    // control or meta keys aren't held down (so it isn't a special key or
    // combination either), block input
    if (
      blockNonNumeric
      && !metaKeyDown
      && !ctrlKeyDown
      && (!(/[0-9]/.test(e.key) || e.key.length > 1))) {
      e.preventDefault();
      return;
    }

    if (
      !displayOptions
      & (
        (e.key === 'ArrowDown')
        || (e.key === 'ArrowUp')
        || (e.key === 'Enter')
        || (e.key === 'Tab')
      )
    ) {
      return;
    }

    switch (e.key){
    case 'ArrowDown':
      e.preventDefault();
      setListIndex(listIndex < options.length - 1 ?  listIndex + 1 : 0);
      break;
    case 'ArrowUp':
      e.preventDefault();
      setListIndex(listIndex === 0 ?  options.length - 1 : listIndex - 1);
      break;
    case 'Enter':
      e.preventDefault();
      setValue(options[listIndex]);
      break;
    case 'Tab':
      setValue(options[listIndex]);
      break;
    case 'Control':
      setCtrlKeyDown(true);
      break;
    case 'Meta':
      setMetaKeyDown(true);
      break;
    default:
      break;
    }
  };

  const handleKeyUp = (e) => {
    switch (e.key) {
    case 'Enter':
      hideOptions();
      break;
    case 'Control':
      setCtrlKeyDown(false);
      break;
    case 'Meta':
      setMetaKeyDown(false);
      break;
    default:
      break;
    }
  };

  const handleKeyChange = (target) => {
    setValue(target.value);
    setDisplayOptions((target.value && target.value.length > 0));
  };

  const handleMouseDown = (option) => {
    selectOption(option);
  };

  const selectOption = (option) => {
    setValue(option);
    hideOptions();
  };

  const hideOptions = () => {
    setOptions([]);
    setDisplayOptions(false);
  };

  return (
    <div className={`dd-wrapper-input ${error && 'error-validation'}`}>
      <input type={'text'}
        ref={inputRef}
        id={inputId}
        value={value}
        disabled={loading || disabled ? 'disabled' : ''}
        onBlur={() => hideOptions()}
        onChange={(({target}) => handleKeyChange(target))}
        onKeyDown={(e) => handleKeyDown(e)}
        onKeyUp={(e) => handleKeyUp(e)}
        maxLength={'100'}
        required
        placeholder={placeholderText}
        autoComplete={'off'}/>
      {value === '' &&
        <label htmlFor={inputId}>{label}</label>
      }
      {
        options.length > 0 && displayOptions && value.length >= minSearchLength &&
        <ul className={'options'}>
          {options.map((option, idx) => (
            <li className={idx === listIndex ? 'selected' : ''}
              onMouseDown={() => handleMouseDown(option)}
              key={idx}>{option}</li>
          ))}
        </ul>
      }
    </div>
  );
};

TypeAheadInput.propTypes = {
  list: PropTypes.array,
  value: PropTypes.string,
  error: PropTypes.bool,
  setValue: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  inputId: PropTypes.string,
  defaultListOption: PropTypes.string,
  placeholderText: PropTypes.string,
  blockNonNumeric: PropTypes.bool,
  minSearchLength: PropTypes.number
};

export default TypeAheadInput;
