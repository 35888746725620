import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import Accordion from '../Accordion';
import AccordionSection from '../AccordionSection';
import BasicsFormSection from '../BasicsFormSection';
import DetailsFormSection from '../DetailsFormSection';
import './styles.css';
import InfoFormSection from '../InfoFormSection';
import FunnelSuccessMessage from '../FunnelSuccessMessage';
import {sendLead} from '../../common/api';
import {SENDING_LEAD_VALUE, SENT_LEAD_VALUE} from '../../common/constants';
import {endSession, updateSession} from '../../common/sessionHelper';

const FunnelMasterComponent = (props) => {
  const {
    formProps,
    text,
    funnelSuccessMessageData,
    errorMessages,
    endpoints,
    trackingEvent
  } = props;
  console.log('formProps', formProps);
  let { sessionData } = props;
  console.log('sessionData', sessionData);

  const getDefaultAccordianState = ( () => {
    let accordionDefaults = {
      basics: {
        wasActive: true,
        active: true,
        complete: false,
      },
      details: {
        wasActive: false,
        active: false,
        complete: false,
      },
      info: {
        wasActive: false,
        active: false,
        complete: false,
      }
    };
    if (sessionData.isSession && sessionData.lastPosition === 1) {
      accordionDefaults.basics.wasActive = true;
      accordionDefaults.basics.active = false;
      accordionDefaults.basics.complete = true;
      accordionDefaults.details.wasActive = true;
      accordionDefaults.details.active = true;
    } else if (sessionData.isSession && sessionData.lastPosition === 2){
      accordionDefaults.basics.complete = true;
      accordionDefaults.basics.active = false;
      accordionDefaults.details.wasActive = true;
      accordionDefaults.details.active = false;
      accordionDefaults.details.complete = true;
      accordionDefaults.info.wasActive = true;
      accordionDefaults.info.active = true;
    }
    return accordionDefaults;
  });

  const [accordionState, setAccordionState] = useState(getDefaultAccordianState());

  const [headerText, setHeaderText] = useState({
    title: text.basicsHeaderTitle,
    subtitle: text.basicsHeaderSubtitle
  });

  const [leadStatus, setLeadStatus] = useState('');

  const editSection = (e, selectedSection) => {
    e.preventDefault();
    if (selectedSection === 'basics') {
      trackingEvent && props.trackingEvent('driveway direct', '03 edit - basics');
      setAccordionState({
        basics: {
          wasActive: true,
          active: true,
          complete: false,
        },
        details: {
          wasActive: false,
          active: false,
          complete: false,
        },
        info: {
          wasActive: false,
          active: false,
          complete: false,
        }
      });
    }

    if (selectedSection === 'details') {
      trackingEvent && props.trackingEvent('driveway direct', '04 edit - details');
      setAccordionState({
        basics: {
          wasActive: true,
          active: false,
          complete: true,
        },
        details: {
          wasActive: true,
          active: true,
          complete: false,
        },
        info: {
          wasActive: false,
          active: false,
          complete: false,
        }
      });
    }
  };

  const onSubmit = async (funnelProps) => {
    trackingEvent && props.trackingEvent('driveway direct', '04 - submit');
    setLeadStatus(SENDING_LEAD_VALUE);
    console.log('FORM PROPS - on submit', formProps);
    const res =  await sendLead(funnelProps, formProps, endpoints.leadEndpoint);
    setLeadStatus(res);
    endSession();
  };

  useEffect(() => {
    const activeSection = Object.keys(accordionState).filter((e) => accordionState[e].active);
    setHeaderText({
      title: text[`${activeSection}HeaderTitle`],
      subtitle: text[`${activeSection}HeaderSubtitle`]
    });
  }, [accordionState]);

  useEffect(() => {
    formProps.salesforceId = sessionData && sessionData.formProps ? sessionData.formProps.salesforceId : undefined;
  },[props]);

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: sessionData.funnelData || {
      basics: {
        trailer: '',
        engineCount: '',
        driveType: '',
        engine1: {
          make: '',
          horsepower: NaN,
          hours: NaN,
          model: ''
        },
        engine2: {
          make: '',
          horsepower: NaN,
          hours: NaN,
          model: ''
        },
        engine3: {
          make: '',
          horsepower: NaN,
          hours: NaN,
          model: ''
        },
        engine4: {
          make: '',
          horsepower: NaN,
          hours: NaN,
          model: ''
        }
      },
      details: {
        zipcode: '',
        problems: '',
        saltwater: '',
        boatCondition: '',
        backView: '',
        backViewUrl: '',
        cockpitView: '',
        cockpitViewUrl: '',
        deckView: '',
        deckViewUrl: '',
        enginesView: '',
        enginesViewUrl: '',
        frontView: '',
        frontViewUrl: '',
        leftView: '',
        leftViewUrl: '',
        rightView: '',
        rightViewUrl: ''
      },
      info: {
        fullName: formProps.contact.fullName.value,
        phone: formProps.contact.phone.value,
        email: formProps.contact.email.value,
        loan: '',
        warranty: '',
        intentSell: ''
      },
    }
  });

  const onNext = (completedAccordian, nextState) => {
    let trackingEventName = completedAccordian === 1 ? '02 - next - basics' : '03 - next - details';
    trackingEvent && props.trackingEvent('driveway direct', trackingEventName);
    sessionData = updateSession(formProps, formMethods.getValues(), completedAccordian);
    setAccordionState(nextState);
  };


  formMethods.watch();

  return leadStatus !== SENT_LEAD_VALUE ? (
    <div className={'master-container'}>
      <div>
        <h1>{headerText.title}</h1>
      </div>
      <div>
        <h4>{headerText.subtitle}</h4>
      </div>
      <div id="boatinfo-container">
        <h5>{formProps.boat.year.value} {formProps.boat.make.value} {formProps.boat.model.value}</h5>
      </div>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
          <Accordion completedIconAlt="completed step" incompleteIconAlt="incomplete step" editIconAlt="edit step">
            <AccordionSection
              title={text.basicsAccordionTitle}
              active={accordionState.basics.active}
              complete={accordionState.basics.complete}
              order={1}
              section={'basics'}
              editSection={editSection}
            >
              <BasicsFormSection
                {...props.basicsProps}
                foundMakes={formProps.foundMakes}
                boatYear={formProps.boat.year.value}
                engineModelsEndpoint={endpoints.engineModelsEndpoint}
                errorMessages={errorMessages}
                onClickNext={() => onNext(1 , {
                  ...accordionState,
                  basics: {
                    ...accordionState.basics,
                    active: false,
                    complete: true
                  },
                  details: {
                    wasActive: true,
                    active: true,
                    complete: false
                  }
                })}
              />
            </AccordionSection>

            <AccordionSection
              title={text.detailsAccordionTitle}
              active={accordionState.details.active}
              complete={accordionState.details.complete}
              order={2}
              section={'details'}
              editSection={editSection}
            >
              <DetailsFormSection
                {...props.detailsProps}
                onClickNext={() => onNext(2, {
                  ...accordionState,
                  details: {
                    ...accordionState.details,
                    active: false,
                    complete: true
                  },
                  info: {
                    wasActive: true,
                    active: true,
                    complete: false
                  }
                })}
              />
            </AccordionSection>

            <AccordionSection
              title={text.infoAccordionTitle}
              active={accordionState.info.active}
              complete={accordionState.info.complete}
              order={3}
              section={'info'}
              editSection={editSection}
            >
              <InfoFormSection
                {...props.infoProps}
                leadStatus={leadStatus}
                errorMessages={errorMessages}/>
            </AccordionSection>
          </Accordion>
        </form>
      </FormProvider>
    </div>
  )
    : <FunnelSuccessMessage {...funnelSuccessMessageData} />;
};

FunnelMasterComponent.propTypes = {
  trackingEvent: PropTypes.func,
  text: PropTypes.shape({
    basicsHeaderTitle: PropTypes.string,
    basicsHeaderSubtitle: PropTypes.string,
    basicsAccordionTitle: PropTypes.string,
    detailsHeaderTitle: PropTypes.string,
    detailsHeaderSubtitle: PropTypes.string,
    detailsAccordionTitle: PropTypes.string,
    infoHeaderTitle: PropTypes.string,
    infoHeaderSubtitle: PropTypes.string,
    infoAccordionTitle: PropTypes.string
  }).isRequired,
  basicsProps: PropTypes.object.isRequired,
  detailsProps: PropTypes.object.isRequired,
  infoProps: PropTypes.object.isRequired,
  sessionData: PropTypes.object.isRequired,
  formProps: PropTypes.shape({
    salesforceId: PropTypes.string,
    contact: PropTypes.shape({
      phone: PropTypes.shape({
        value: PropTypes.string
      }),
      email: PropTypes.shape({
        value: PropTypes.string
      }),
      zip: PropTypes.shape({
        value: PropTypes.string
      }),
      fullName: PropTypes.shape({
        value: PropTypes.string
      })
    }),
    boat: PropTypes.shape({
      make: PropTypes.shape({
        value: PropTypes.string
      }),
      model: PropTypes.shape({
        value: PropTypes.string
      }),
      year: PropTypes.shape({
        value: PropTypes.string
      }),
      length: PropTypes.shape({
        value: PropTypes.number
      })
    }),
    foundMakes: PropTypes.array
  }).isRequired,
  funnelSuccessMessageData: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string
  }).isRequired,
  errorMessages: PropTypes.object.isRequired,
  endpoints: PropTypes.shape({
    leadEndpoint: PropTypes.string,
    makesEndpoint: PropTypes.string,
    modelsEndpoint: PropTypes.string,
    engineModelsEndpoint: PropTypes.string
  })
};

export default FunnelMasterComponent;
