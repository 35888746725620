import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FormFieldHeader from '../FormFieldHeader';
import TypeAheadInput from '../TypeAheadInput';
import {getModels} from '../../common/api';
// TODO: add this back in once agreement component can be used without form field registration:
// import Agreement from '../Agreement';

const BasicFormEngineSubsection = (props) => {
  const {
    order,
    text,
    makes,
    boatYear,
    engineModelsEndpoint,
    errorMessages
  } = props;

  const { register, getValues, setValue, control } = useFormContext();
  const [shouldSetModelSame, setShouldSetModelSame] = useState(false);
  const [foundModels, setFoundModels] = useState([]);
  const values = getValues();
  const [isSameChecked, setSameChecked] = useState(!!values.basics[`engine${order}`]?.checked);

  const findModels = (make, year, endpoint) => {
    getModels(make, year, endpoint).then(models => {
      setFoundModels(models);
    });
  };

  useEffect(() => {
    setValue(`basics.engine${order}.checked`, isSameChecked);
    if (isSameChecked) {
      setValue(`basics.engine${order}.make`, values.basics.engine1.make, {
        shouldTouch: true,
      });

      setValue(`basics.engine${order}.horsepower`, values.basics.engine1.horsepower, {
        shouldTouch: true,
      });

      // see next comment for explanation of this line:
      setShouldSetModelSame(true);
    }
  }, [isSameChecked, values.basics.engine1?.make, values.basics.engine1?.model, values.basics.engine1?.horsepower]);

  // we need to set the model on the rerender after make is set (see above
  // useEffect) or else the model list may be empty
  useEffect(() => {
    if (shouldSetModelSame) {
      setValue(`basics.engine${order}.model`, values.basics.engine1.model, {
        shouldTouch: true,
      });

      setShouldSetModelSame(false);
    }
  }, [shouldSetModelSame]);

  return (
    <div className={`engine-subsection-wrapper-${order}`}>
      <div className="engine-pill"><span>{text.engine} {order}</span></div>

      {order > 1 && (
        <div className="is-same-wrapper">
          <label className="container-checkbox">
            <div className={'wrapper-checkbox'}>
              <input
                type="checkbox"
                checked={isSameChecked}
                onChange={() => setSameChecked(!isSameChecked)}
              />
              <span className="checkbox-styled default"/>
            </div>
            <div className={'wrapper-checkbox'}>
              {text.same}
            </div>
          </label>

          {/* TODO: add this back in once agreement component can be used without
          form field registration: */}
          {/* <Agreement
            id={'is-same-engine'}
            agreement={text.same}
            value={isSameChecked}
            setValue={setSameChecked}
          /> */}
        </div>
      )}

      <div className="engine-make-wrapper">
        <FormFieldHeader titleText={text.makeTitle} tipText={text.makeTip} field={`engine${order}.make`}/>

        <Controller
          control={control}
          name={`basics.engine${order}.make`}
          defaultValue=""
          rules={{validate: value => [...makes, 'custom/other'].indexOf(value) !== -1}}
          render={({
            field: { onChange, value },
            fieldState: { invalid },
          }) => (
            <div onBlur={() => findModels({value, error: invalid}, boatYear, engineModelsEndpoint)}>
              <TypeAheadInput
                list={makes}
                setValue={onChange}
                value={value}
                inputId={`engine${order}.make`}
                defaultListOption={'custom/other'}
                loading={false}
                error={invalid}
                placeholderText={text.selectPlaceholder}
                disabled={isSameChecked}
                minSearchLength={3}
              />
              {invalid  && <div className={'message-error'}>{errorMessages.make}</div>}
            </div>
          )}
        />
      </div>

      <div className="engine-model-wrapper">
        <FormFieldHeader
          titleText={text.modelTitle}
          tipText={text.modelTip}
          required={false}
          field={`engine${order}.model`}/>

        <Controller
          control={control}
          name={`basics.engine${order}.model`}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TypeAheadInput
              list={foundModels}
              setValue={onChange}
              value={value}
              inputId={`engine${order}.model`}
              loading={false}
              placeholderText={text.selectPlaceholder}
              disabled={isSameChecked}
            />
          )}
        />
      </div>

      <div className="horsepower-wrapper">
        <FormFieldHeader
          titleText={text.horsepowerTitle}
          tipText={text.horsepowerTip}
          field={`basics.engine${order}.horsepower`}/>

        <div className={classNames('input-number', {'input--disabled': isSameChecked})}>
          <Controller
            control={control}
            name={`basics.engine${order}.horsepower`}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <NumberFormat
                onValueChange={(v) => onChange(Number.parseInt(v.value))}
                value={value}
                suffix=" (HP)"
                placeholder={text.horsepowerPlaceholder}
                id={`basics.engine${order}.horsepower`}
              />
            )}/>
          <span className="focus"></span>

        </div>
      </div>

      <div className="hours-wrapper">
        <FormFieldHeader titleText={text.hoursTitle} tipText={text.hoursTip} field={`basics.engine${order}.hours`}/>

        <div className="input-number">
          <input id={`basics.engine${order}.hours`}
            {...register(`basics.engine${order}.hours`, {
              valueAsNumber: true,
              required: true,
              default: ''
            })}
            type="number"
            placeholder={text.hoursPlaceholder}
          />

          <span className="focus"></span>
        </div>
      </div>
    </div>
  );
};

BasicFormEngineSubsection.propTypes = {
  order: PropTypes.number.isRequired,
  makes: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.shape({
    makeTitle: PropTypes.string.isRequired,
    makeTip: PropTypes.string.isRequired,
    modelTitle: PropTypes.string.isRequired,
    modelTip: PropTypes.string.isRequired,
    horsepowerTitle: PropTypes.string.isRequired,
    horsepowerTip: PropTypes.string.isRequired,
    hoursTitle: PropTypes.string.isRequired,
    hoursTip: PropTypes.string.isRequired,
    selectPlaceholder: PropTypes.string.isRequired,
    horsepowerPlaceholder: PropTypes.string.isRequired,
    hoursPlaceholder: PropTypes.string.isRequired,
    same: PropTypes.string.isRequired,
    engine: PropTypes.string.isRequired,
  }),
  boatYear: PropTypes.shape({
    value: PropTypes.string.isRequired
  }),
  engineModelsEndpoint: PropTypes.string.isRequired,
  errorMessages: PropTypes.object.isRequired
};

export default BasicFormEngineSubsection;
