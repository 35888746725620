import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import BasicFormEngineSubsection from '../BasicFormEngineSubsection';
import BinaryRadio from '../BinaryRadio';
import FormFieldHeader from '../FormFieldHeader';
import './styles.css';
import '../../common/form.common.css';
import '../../common/funnelForm.common.css';

const BasicsFormSection = (props) => {
  const { text, driveTypes, foundMakes, boatYear, engineModelsEndpoint, onClickNext, errorMessages } = props;

  const { register, watch, getValues, unregister, formState: { isValid } } = useFormContext();
  watch();

  const { basics: { engineCount } } = getValues();

  useEffect(() => {
    if (engineCount < 4) {
      unregister('basics.engine4', { keepValue: true });
    }

    if (engineCount < 3) {
      unregister('basics.engine3', { keepValue: true });
    }

    if (engineCount < 2) {
      unregister('basics.engine2', { keepValue: true });
    }
  }, [engineCount]);

  return (
    <div className="funnel-form basics-form">
      <div className="trailer-wrapper">
        <BinaryRadio
          name="basics.trailer"
          required={true}
          id={{yes: 'trailerYes', no: 'trailerNo'}}
          testId={{ yes: 'trailerYes', no: 'trailerNo' }}
          value={{yes: 'yes', no: 'no'}}
          text={{
            title: text.trailerTitle,
            tip: text.trailerTip,
            yes: text.trailerYes,
            no: text.trailerNo
          }}
        />
      </div>

      <div className="engine-count-wrapper">
        <FormFieldHeader titleText={text.engineCountTitle} tipText={text.engineCountTip} field="engine-count"/>

        <div className="select">
          <select id="engine-count"
            {...register('basics.engineCount', {
              required: true,
              valueAsNumber: true
            })}
          >
            <option value="">{text.selectPlaceholder}</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
          </select>

          <span className="focus"></span>
        </div>
      </div>

      <div className="drive-type-wrapper">
        <FormFieldHeader titleText={text.driveTypeTitle} tipText={text.driveTypeTip} field="drive-type"/>

        <div className="select">
          <select id="drive-type"
            {...register('basics.driveType', {
              required: true
            })}
          >
            <option value="">{text.selectPlaceholder}</option>
            {driveTypes.map((driveType) => (<option key={driveType} value={driveType}>{driveType}</option>))}
          </select>

          <span className="focus"></span>
        </div>
      </div>

      {engineCount ? new Array(engineCount).fill(null).map((_, index) => {return (
        <BasicFormEngineSubsection
          key={index}
          order={index + 1}
          text={{
            makeTitle: text.makeTitle,
            makeTip: text.makeTip,
            modelTitle: text.modelTitle,
            modelTip: text.modelTip,
            horsepowerTitle: text.horsepowerTitle,
            horsepowerTip: text.horsepowerTip,
            hoursTitle: text.hoursTitle,
            hoursTip: text.hoursTip,
            selectPlaceholder: text.selectPlaceholder,
            horsepowerPlaceholder: text.horsepowerPlaceholder,
            hoursPlaceholder: text.hoursPlaceholder,
            same: text.same,
            engine: text.engine
          }}
          makes={foundMakes}
          boatYear={boatYear}
          engineModelsEndpoint={engineModelsEndpoint}
          errorMessages={errorMessages}
        />
      );}) : ''}

      <input type="button"
        data-testid="basics-next"
        className="button-submit-funnel"
        onClick={onClickNext}
        value={text.next}
        disabled={!isValid}
      />
    </div>
  );
};

BasicsFormSection.propTypes = {
  driveTypes: PropTypes.arrayOf(PropTypes.string),
  foundMakes: PropTypes.arrayOf(PropTypes.string).isRequired,
  boatYear: PropTypes.shape({
    value: PropTypes.string.isRequired
  }),
  engineModelsEndpoint: PropTypes.string.isRequired,
  onClickNext: PropTypes.func,
  errorMessages: PropTypes.object,
  text: PropTypes.shape({
    next: PropTypes.string.isRequired,
    trailerTitle: PropTypes.string.isRequired,
    trailerTip: PropTypes.string.isRequired,
    trailerYes: PropTypes.string.isRequired,
    trailerNo: PropTypes.string.isRequired,
    engineCountTitle: PropTypes.string.isRequired,
    engineCountTip: PropTypes.string.isRequired,
    driveTypeTitle: PropTypes.string.isRequired,
    driveTypeTip: PropTypes.string.isRequired,
    makeTitle: PropTypes.string.isRequired,
    makeTip: PropTypes.string.isRequired,
    modelTitle: PropTypes.string.isRequired,
    modelTip: PropTypes.string.isRequired,
    horsepowerTitle: PropTypes.string.isRequired,
    horsepowerTip: PropTypes.string.isRequired,
    hoursTitle: PropTypes.string.isRequired,
    hoursTip: PropTypes.string.isRequired,
    selectPlaceholder: PropTypes.string.isRequired,
    horsepowerPlaceholder: PropTypes.string.isRequired,
    hoursPlaceholder: PropTypes.string.isRequired,
    same: PropTypes.string.isRequired,
    engine: PropTypes.string.isRequired
  }),
};

export default BasicsFormSection;
