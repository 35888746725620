import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { createPortal } from 'react-dom';
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';
import { ESCAPE_KEY_CODE } from '../../common/Constants.common';

import './Modal.css';


const Modal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  useEffect(() => setShow(props.autoOpen), [props.autoOpen]);

  useEffect(() => {
    if (props.close.keyboard) {
      window.addEventListener('keydown', handleEscapeKeydown);

      return () => {
        window.removeEventListener('keydown', handleEscapeKeydown);
      };
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
      openModal: () => open(),
      closeModal: () => close()
    };
  });

  const open = () => {
    setShow(true);
  };

  const close = () => {
    setShow(false);
  };

  const handleEscapeKeydown = ({ which }) => {
    if (which === ESCAPE_KEY_CODE) {
      close();
    }
  };

  if (show) {
    return createPortal(
      <FocusTrap focusTrapOptions={{clickOutsideDeactivates: true}}>
        <div role="dialog" aria-modal="true" className="modal-container modal-base">
          <div className="modal-backdrop modal-base"/>
          <div className="modal-box modal-base">
            <div className="lib-modal-header-container">
              <h1 className="lib-modal-header modal-base">{props.title}</h1>
              {props.close.button && <button title="close" className="modal-close-button" onClick={close}/>}
            </div>
            <div className="modal-contents">
              {props.children}
            </div>
          </div>
        </div>
      </FocusTrap>,
      document.getElementById('modal-root')
    );
  }

  return null;
});

Modal.displayName = 'Modal';
Modal.propTypes = {
  title: PropTypes.string,
  autoOpen: PropTypes.bool,
  close: PropTypes.shape({
    button: PropTypes.bool,
    keyboard: PropTypes.bool
  }),
  children: PropTypes.node
};

Modal.defaultProps = {
  autoOpen: false,
  close: {
    button: true,
    keyboard: true
  }
};

export default Modal;
