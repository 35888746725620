import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Hub } from 'aws-amplify';
import VerifyUserNameForm from '../ForgottenPasswordFlow/VerifyUserNameForm';
import Modal from '../Modal';
import Tabs from '../Tabs';
import Tab from '../Tabs/Tab';
import SigninForm from '../SigninForm';
import SignupForm from '../SignupForm';
import UserMenu from '../UserMenu';
import VerificationCodeForm from '../SignupEmailVerificationFlow/VerificationCodeForm';
import Confirmation from '../SignupEmailVerificationFlow/Confirmation';
import { cognitoInit,
  forgotPassword,
  getCurrentUser,
  getCurrentAuthenticatedUser,
  updateSocialUserLocale,
  signIn,
  signUp,
  submitNewPassword,
  confirmSignUp,
  resendSignUp
} from '../../common/util/amplify';
import fieldHasError from '../../common/util/fieldHasError';
import { setTracking, modalErrorSignIn, modalOpenForgotPassword, modalOpenSignIn, modalErrorSignUp, modalErrorForgotPassword, modalOpenNormalVerify, modalErrorNormalVerify } from '../../common/util/dataLayer';
import { redirect, setRedirect, redirectOauthSignOut, SESSION_STORAGE_KEY } from '../../common/util/redirectHelper';

import '../../common/Form.common.css';
import './User.css';
import { getExceptionMessage } from './exceptionMessages';

const User = forwardRef((props, ref) => {

  const modalRef = React.useRef();
  const [ signUpErrors, setSignUpErrors] = useState({email: {}, password: {}});
  const [ signInErrors, setSignInErrors ] = useState({username: {}, password: {}});
  const [ forgotPasswordErrors, setForgotPasswordErrors] = useState({email: {}});
  const [ newPasswordErrors, setNewPasswordErrors] = useState({code: {}, password: {}, confirmPassword: {}});
  const [ activeTab, setActiveTab ] = useState(null);
  const [ modalTitle, setModalTitle ] = useState(props.title.main);
  const [ user, setUser ] = useState();
  const [ emailVerificationErrors, setEmailVerificationErrors] = useState({code: {}});
  const [ unverifiedEmail, setUnverifiedEmail ] = useState('');

  useEffect(async () => {
    cognitoInit(props.cognitoConfig);
    const authUser = await getCurrentAuthenticatedUser();
    setUser(getCurrentUser(authUser));
    redirectOauthSignOut(SESSION_STORAGE_KEY.OAUTH_SIGN_OUT);
    setModalTitle(props.options.defaultView.title);
    props.onInit();
  }, []);

  useEffect(() => {
    const authEventListener = async (data)  => {
      if (data.payload.event === 'signIn') {
        const authUser = await getCurrentAuthenticatedUser();
        setUser(getCurrentUser(authUser));
        await updateSocialUserLocale(authUser, props.profile.locale);
        redirect(SESSION_STORAGE_KEY.REDIRECT_URL);
        modalRef.current.closeModal();
        props.onSignInSuccess();
      } else if (data.payload.event === 'signUp') {
        props.onCreateAccountSuccess();
      } else if (data.payload.event === 'signOut') {
        setUser({ });
      } else if (data.payload.event === 'oAuthSignOut') {
        // oAuthSignOut is only triggered on sign-out if
        // the user signed in using Apple, Google, etc.
        window.sessionStorage.setItem(SESSION_STORAGE_KEY.OAUTH_SIGN_OUT, window.location.href);
      }
    };

    Hub.listen('auth', authEventListener);

    return () => {
      Hub.remove('auth', authEventListener);
    };
  }, []);

  useImperativeHandle(ref, () => ({
    onOpenModal() {
      openModal(props.tabs.label.signin);
    }
  }));

  const [ resetPasswordValues, setResetPasswordValues] = useState({ email: '', code: '', isPasswordSent: false, isPasswordValidated: false });

  const onTabChange = () => {
    setSignUpErrors({email: {}, password: {}});
    setSignInErrors({username: {}, password: {}});
  };

  const openModal = (tabLabel) => {
    setActiveTab(tabLabel);
    setModalTitle(props.title.main);
    setResetPasswordValues({ email: '', code: '', isPasswordSent: false, isPasswordValidated: false });
    setSignUpErrors({email: {}, password: {}});
    setSignInErrors({username: {}, password: {}});
    setForgotPasswordErrors({email: {}});
    setNewPasswordErrors({code: {}, password: {}, confirmPassword: {}});
    setEmailVerificationErrors({code: {}});
    modalRef.current.openModal();
  };

  const doSignIn = async (values, clientErrors) => {
    if (!fieldHasError(clientErrors, 'username') && !fieldHasError(clientErrors, 'password')) {
      const newErrors = {
        username: {},
        password: {},
      };
      setSignInErrors(newErrors);
      try {
        setUnverifiedEmail(values.username);
        await signIn(values.username, values.password);
      } catch (error) {
        if (get(error, 'code') === 'UserNotConfirmedException') {
          await resendCodeEmailVerification(values.username);
          setModalTitle(props.signUpEmailVerificationFlow.verificationCode.title);
          setTracking(modalOpenNormalVerify);
        } else {
          const { message, position } = getExceptionMessage(error.code, 'signIn', 'password', props.serverErrorsMessages);
          setSignInErrors({
            ...newErrors,
            [position]: {
              [message]: true,
            },
          });
        }
        setTracking(modalErrorSignIn);
      } finally {
        setRedirect({ defaultView: props.options.defaultView, isFederated: false });
      }
    }
  };

  const doSignUp = async (values, clientErrors) => {
    if (!fieldHasError(clientErrors, 'email') && !fieldHasError(clientErrors, 'password')) {
      const newErrors = {
        email: {},
        password: {},
      };
      setSignUpErrors(newErrors);
      try {
        await signUp(values.email, values.password, props.profile.locale);
        setUnverifiedEmail(values.email);
        setModalTitle(props.signUpEmailVerificationFlow.verificationCode.title);
        setTracking(modalOpenNormalVerify);
      } catch (error) {
        const { message, position } = getExceptionMessage(error.code, 'signUp', 'password', props.serverErrorsMessages);
        setSignUpErrors({
          ...newErrors,
          [position]: {
            [message]: true,
          },
        });
        setTracking(modalErrorSignUp);
      }
    }
  };

  const doEmailVerification = async (code) => {
    try {
      setEmailVerificationErrors({code: {}});
      await confirmSignUp(unverifiedEmail, code);

    } catch (error) {
      const { message, position } = getExceptionMessage(error.code, 'emailVerification', 'code', props.serverErrorsMessages);
      setEmailVerificationErrors({
        [position]: {
          [message]: true
        },
      });
      setTracking(modalErrorNormalVerify);
    }
  };

  const resendCodeEmailVerification = async (username) => {
    try {
      setEmailVerificationErrors({code: {}});
      await resendSignUp(unverifiedEmail || username);
    } catch (error) {
      const { message, position } = getExceptionMessage(error.code, 'emailVerification', 'code', props.serverErrorsMessages);
      setEmailVerificationErrors({
        [position]: {
          [message]: true
        },
      });
    }
  };

  const submitForgotPassword = async (values, clientErrors) => {
    if (!fieldHasError(clientErrors, 'email')) {
      const newErrors = {
        email: {},
      };
      setForgotPasswordErrors(newErrors);
      try {
        await forgotPassword(values.email);
        setResetPasswordValues({ ...resetPasswordValues, email: values.email, isPasswordSent: true });
      } catch (error) {
        const { message, position } = getExceptionMessage(error.code, 'forgotPassword', 'email', props.serverErrorsMessages);
        setForgotPasswordErrors({
          ...newErrors,
          [position]: {
            [message]: true,
          },
        });
        setTracking(modalErrorForgotPassword);
      }
    }
  };

  const newPasswordProps = {
    ...props.forgottenPasswordFlow?.newPasswordForm?.formProps,
    serverErrors: newPasswordErrors,
    resendFunction: async () => {
      const newErrors = {
        code: {},
        password: {},
        confirmPassword: {},
      };
      try {
        await forgotPassword(resetPasswordValues.email);
      } catch (error) {
        const { message, position } = getExceptionMessage(error.code, 'newPassword', 'confirmPassword', props.serverErrorsMessages);
        setNewPasswordErrors({
          ...newErrors,
          [position]: {
            [message]: true,
          },
        });
      }
    },
    submitForm: async (values, clientErrors) => {
      if (!fieldHasError(clientErrors, 'password') && !fieldHasError(clientErrors, 'confirmPassword') && !fieldHasError(clientErrors, 'code')) {
        const newErrors = {
          code: {},
          password: {},
          confirmPassword: {},
        };
        setNewPasswordErrors(newErrors);
        try {
          await submitNewPassword(resetPasswordValues.email, values.code, values.password);
          setModalTitle(props.forgottenPasswordFlow.confirmation.title);
        } catch (error) {
          const { message, position } = getExceptionMessage(error.code, 'newPassword', 'confirmPassword', props.serverErrorsMessages);
          setNewPasswordErrors({
            ...newErrors,
            [position]: {
              [message]: true
            },
          });
        }
      }
    }
  };

  const backFunctionSignIn = () => {
    setTracking(modalOpenSignIn);
    setModalTitle(props.title.main);
    setActiveTab(props.tabs.label.signin);
  };

  const tabComponentArray = () => {
    const tabs = [
      <Tab label={props.tabs.label.signin} dataTestid="signin-tab" key={'signin-tab'}>
        <SigninForm
          submitForm={doSignIn}
          {...props.signinFormProps}
          serverErrors={signInErrors}
          backFunction={() => {
            setTracking(modalOpenForgotPassword);
            setSignInErrors({username: {}, password: {}});
            setModalTitle(props.forgottenPasswordFlow.defaultTitle);
          }}
          options={{
            socialAuthentication: props.options.socialAuthentication,
            defaultView: props.options.defaultView
          }}
        />
      </Tab>
    ];
    if (props.options.defaultView && props.options.defaultView.signup) {
      tabs.push(
        <Tab label={props.tabs.label.signup} dataTestid="signup-tab" key={'signup-tab'}>
          <SignupForm
            submitForm={doSignUp}
            {...props.signupFormProps}
            serverErrors={signUpErrors}
            options={{
              socialAuthentication: props.options.socialAuthentication,
              defaultView: props.options.defaultView
            }}
          />
        </Tab>
      );
    }
    return tabs;
  };

  return (
    <>
      {user && <UserMenu
        {...props.userMenuProps}
        options={{navBarMenu: props.options.navBarMenu}}
        signInClick={() => openModal(props.tabs.label.signin)}
        signUpClick={() => openModal(props.tabs.label.signup)}
        signOutClick={() => props.onSignOut()}
        user={user}
      />}
      <Modal
        autoOpen={props.options.defaultView.autoOpen && !user?.username}
        close={props.options.close}
        title={modalTitle}
        ref={modalRef}
      >
        <div className="user-modal-content">
          { (modalTitle === props.title.main) &&
            <Tabs initialTabSelection={activeTab} onTabChange={onTabChange}>
              {tabComponentArray()}
            </Tabs>
          }
          { (modalTitle === props.forgottenPasswordFlow.defaultTitle) &&
            <VerifyUserNameForm
              {...props.forgottenPasswordFlow?.verifyUserNameForm?.formProps}
              submitForm={submitForgotPassword}
              backFunction={() => {
                setForgotPasswordErrors({email: {}});
                setNewPasswordErrors({code: {}, password: {}, confirmPassword: {}});
                backFunctionSignIn();
              }}
              newPasswordFormProps={newPasswordProps}
              serverErrors={forgotPasswordErrors}
              sent={resetPasswordValues.isPasswordSent}
              validated={resetPasswordValues.isPasswordValidated}
            />
          }
          { (modalTitle === props.forgottenPasswordFlow.confirmation.title) &&
          <Confirmation
            {...props.forgottenPasswordFlow.confirmation.formProps}
            backFunction={backFunctionSignIn}
          />
          }
          { (modalTitle === props.signUpEmailVerificationFlow.verificationCode.title) &&
            <VerificationCodeForm
              {...props.signUpEmailVerificationFlow.verificationCode.formProps}
              submitForm={doEmailVerification}
              resendCodeFunction={resendCodeEmailVerification}
              serverErrors={emailVerificationErrors}
            />
          }
        </div>
      </Modal>
    </>
  );
});

User.displayName = 'User Form';
User.propTypes = {
  options: PropTypes.shape({
    defaultView: PropTypes.shape({
      autoOpen: PropTypes.bool,
      title: PropTypes.string,
      signup: PropTypes.bool
    }),
    close: PropTypes.shape({
      button: PropTypes.bool,
      keyboard: PropTypes.bool
    }),
    navBarMenu: PropTypes.shape({
      login: PropTypes.bool,
      signup: PropTypes.bool
    }),
    socialAuthentication: PropTypes.shape({
      apple: PropTypes.bool,
      google: PropTypes.bool
    })
  }),
  profile: PropTypes.shape({
    locale: PropTypes.string
  }).isRequired,
  tabs: PropTypes.shape({
    label: PropTypes.shape({
      signin: PropTypes.string,
      signup: PropTypes.string
    }),
  }),
  title: PropTypes.shape({
    main: PropTypes.string,
  }),
  forgottenPasswordFlow: PropTypes.shape({
    defaultTitle: PropTypes.string,
    verifyUserNameForm: PropTypes.shape({
      formProps: PropTypes.object
    }),
    newPasswordForm: PropTypes.shape({
      formProps: PropTypes.object
    }),
    confirmation: PropTypes.shape({
      formProps: PropTypes.object,
      title: PropTypes.string
    }),
  }),
  signUpEmailVerificationFlow: PropTypes.shape({
    verificationCode: PropTypes.shape({
      formProps: PropTypes.object,
      title: PropTypes.string
    })
  }),
  userMenuProps: PropTypes.object,
  signinFormProps: PropTypes.object,
  signupFormProps: PropTypes.object,
  cognitoConfig: PropTypes.shape({
    region: PropTypes.string,
    userPoolId: PropTypes.string,
    userPoolWebClientId: PropTypes.string
  }).isRequired,
  onInit: PropTypes.func,
  onSignInSuccess: PropTypes.func,
  onCreateAccountSuccess: PropTypes.func,
  onSignOut: PropTypes.func,
  serverErrorsMessages: PropTypes.object
};

User.defaultProps = {
  options: {
    defaultView: {
      autoOpen: false,
      title: 'Welcome!',
      signup: true
    },
    close: {
      button: true,
      keyboard: true
    },
    navBarMenu: {
      login: true,
      signup: true
    },
    socialAuthentication: {
      apple: true,
      google: true
    }
  },
  profile: {
    locale: 'en-US',
  },
  tabs: {
    label: {
      signin: 'Sign In',
      signup: 'New Account'
    },
  },
  title: {
    main: 'Welcome!',
  },
  forgottenPasswordFlow: {
    defaultTitle: 'Forgot your password?',
    confirmation: {
      formProps: {
        text: {
          headerInformation: {
            link: 'Sign In',
            message1: 'to your account'
          },
          img: {
            successAlt: 'successful sign up'
          }
        }
      },
      title: 'Your Password Has Been Changed',
    }
  },
  signUpEmailVerificationFlow: {
    verificationCode: {
      title: 'Enter Verification Code'
    },
  },
  onInit: () => {},
  onSignInSuccess: () => {},
  onCreateAccountSuccess: () => {},
  onSignOut: () => {}
};

export default User;
