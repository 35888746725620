import React from 'react';
import PropTypes from 'prop-types';
import { signOut } from '../../common/util/amplify';
import { setTracking, modalOpenSignUp, modalOpenSignIn, navigationSignUp, navigationSignIn } from '../../common/util/dataLayer';

import './UserMenu.css';

const UserMenu = ({ user, signUpClick, signInClick, signOutClick, ...props }) => {

  const handleSignUpClick = (e) => {
    e.preventDefault();

    signUpClick();
    setTracking(modalOpenSignUp);
    setTracking(navigationSignUp);
  };

  const handleSignInClick = (e) => {
    e.preventDefault();

    signInClick();
    setTracking(modalOpenSignIn);
    setTracking(navigationSignIn);
  };

  const handleSignOutClick = async () => {
    await signOut();
    signOutClick();
  };

  const getMenuItems = () => {
    return (
      <div className="menu-items" data-e2e="user-menu-items">
        {
          props.menuItems.map((item, i) => {
            return <a className="menu-item" data-e2e="user-menu-item" key={i} href={item.href}>{item.label}</a>;
          })
        }
        <div className="sign-out-button" data-e2e="signout-button" onClick={handleSignOutClick}>{props.signOutLabel}</div>
      </div>);
  };

  return (
    <>
      { (user?.username) &&
          <div className="user-menu user-logged-in" data-e2e="usermenu user-logged-in">
            <div className="user-name" data-e2e="user-info">
              {props.iconUrl ? <img alt="Welcome" src={props.iconUrl}/> : <div className="no-icon"/>}
              <span data-e2e="welcome-label">{props.welcomeLabel}</span>
              <div className="user" data-e2e="username">{user.username}</div>
            </div>
            {getMenuItems()}
          </div>
      }
      { (!user?.username) &&
        <div>
          <div className="user-menu" data-e2e="user-menu">
            {props.options?.navBarMenu?.signup && <div
              className="user-menu-button"
              onClick={handleSignUpClick}
              data-testid="usermenu-signup-button"
              data-e2e="user-menu-signup-button">
              {props.signUpLabel}
            </div>}
            {props.options?.navBarMenu?.login && <div
              className="user-menu-button"
              onClick={handleSignInClick}
              data-testid="usermenu-login-button"
              data-e2e="user-menu-login-button">
              {props.signInLabel}
            </div>}
          </div>
        </div>
      }
    </>
  );
};

UserMenu.propTypes = {
  options: PropTypes.shape({
    navBarMenu: PropTypes.shape({
      login: PropTypes.bool,
      signup: PropTypes.bool
    })
  }),
  user: PropTypes.object,
  iconUrl: PropTypes.string,
  welcomeLabel: PropTypes.string.isRequired,
  signInLabel: PropTypes.string.isRequired,
  signUpLabel: PropTypes.string.isRequired,
  signOutLabel: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  signInClick: PropTypes.func,
  signUpClick: PropTypes.func,
  signOutClick: PropTypes.func
};
UserMenu.defaultProps = {
  options: {
    navBarMenu: {
      login: true,
      signup: true
    }
  },
  welcomeLabel: 'Welcome',
  signInLabel: 'Log in',
  signUpLabel: 'Sign up',
  signOutLabel: 'Sign Out',
  menuItems: [
    {href: 'http://www.signup.com', label: 'Boats I Like'},
    {href: 'http://www.signup.com', label: 'My Boats For Sale'},
    {href: 'http://www.signup.com', label: 'My Profile'}
  ],
  signInClick: () => { },
  signUpClick: () => { },
  signOutClick: () => { }
};

export default UserMenu;
