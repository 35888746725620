import React, {useState, useEffect} from 'react';
import { useFormContext } from 'react-hook-form';
import axios from 'axios';
import classNames from 'classnames';
import './styles.css';
import PropTypes from 'prop-types';
import cancelIcon from './ic_cancel_48px.png';
import spinner from  './preloader.gif';
import {
  INAPPROPRIATE_ERROR_MESSAGE,
  INAPPROPRIATE_ERROR_TYPE,
  IMAGE_UPLOAD_ERROR_MESSAGE,
  IMAGE_UPLOAD_ERROR_TYPE
} from '../../common/constants';

const ImageUploader = (props) => {
  const thumbnailName = `${props.inputName}Thumbnail`;

  const { register, watch, setValue, setError, clearErrors, getValues } = useFormContext();
  watch();

  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(!!getValues(props.urlName));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    try {
      if (!selectedFile) {
        return;
      }
      setIsLoading(true);
      const response = await axios.post(props.endpoint, selectedFile, {
        headers: {
          'Content-Type': selectedFile.type
        }
      });

      setValue(props.urlName, response.data.image, { shouldValidate: false });
      setValue(thumbnailName, response.data.thumbnail, { shouldValidate: false });
      clearErrors(props.urlName);
      setIsLoading(false);
      setIsFilePicked(true);
    } catch (err) {
      setValue(props.urlName, '', { shouldValidate: false });
      setValue(thumbnailName, '', { shouldValidate: false });
      setValue(props.inputName, '', { shouldValidate: true, shouldDirty: true });
      setIsFilePicked(false);
      setIsLoading(false);

      const isInappropriate = err.response?.data === INAPPROPRIATE_ERROR_MESSAGE;

      if (isInappropriate) {
        setError(
          props.urlName,
          {
            type: INAPPROPRIATE_ERROR_TYPE,
            message: err.response.data
          }
        );
      } else {
        setError(
          props.urlName,
          {
            type: IMAGE_UPLOAD_ERROR_TYPE,
            message: IMAGE_UPLOAD_ERROR_MESSAGE
          }
        );
      }
    }
  }, [selectedFile]);

  const changeHandler = async (e) => {
    if (!e.target.files || e.target.files.length === 0 || !e.target.files[0]) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  const cancel = () => {
    setIsFilePicked(false);
    setSelectedFile(undefined);
    setValue(props.urlName, '', { shouldValidate: false });
    setValue(props.inputName, '', { shouldValidate: true, shouldDirty: true });
  };

  return (
    <div className="image-upload-container">
      {isFilePicked && <div className="cancel" onClick={cancel} data-testid={'cancel'}><img src={cancelIcon} /></div> }
      <label className="image-upload">
        <div className="image-upload-boundry">
          {isLoading && <div className={'container-image-loading'}><img src={spinner} alt="loading" className={'image-loading'}/></div>}
          <img
            className={classNames({ 'preview': isFilePicked })}
            src={isFilePicked ? getValues(thumbnailName) : props.icon}
          />
          <input
            {...register(props.inputName, {
              required: props.required,
              onChange: changeHandler
            })}
            type="file"
            accept="image/png, image/jpeg, image/gif"/>
        </div>
      </label>
      {!isFilePicked && <div className="title">{props.title}</div>}
    </div>
  );
};

export default ImageUploader;

ImageUploader.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  // TODO: make required:
  inputName: PropTypes.string,
  urlName: PropTypes.string,
  endpoint: PropTypes.string
};
