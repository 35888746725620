import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import toPairs from 'lodash/toPairs';
import useDeepCompareEffect from 'use-deep-compare-effect';
import useFirstRender from '../../common/hooks/useFirstRender';
import compareError from '../../common/util/compareError';
import '../../common/ValidationErrorList.common.css';
import './ClientValidationErrorList.css';

const ValidationErrorList = (props) => {
  const [displayNeutral, setDisplayNeutral] = useState(false);
  const isFirstRender = useFirstRender();

  // useDeepCompareEffect is necessary because props.errors is an object that
  // needs to be deeply compared (see
  // https://stackoverflow.com/a/59322710/12275630)
  useDeepCompareEffect(() => {
    if (isFirstRender) {
      setDisplayNeutral(true);
    } else {
      setDisplayNeutral(false);
    }
  }, [props.errors]);

  let errorList;

  if (displayNeutral) {
    errorList = (
      <>
        {props.neutralErrorMessages.sort()
          .map((errorMessage) => (
            <li className="error-state-neutral" key={errorMessage}>{errorMessage}</li>
          ))}
      </>
    );
  } else {
    errorList = (
      <>
        {toPairs(props.errors)
          .sort(compareError)
          .map(([errorMessage, errorState]) => (
            <li
              className={classNames({
                'error-state-false': !errorState,
                'error-state-true': errorState
              })}
              key={errorMessage}
            >{errorMessage}</li>
          ))}
      </>
    );
  }

  return (
    <ul className="validation-error-list">
      {errorList}
    </ul>
  );
};

ValidationErrorList.displayName = 'Client Validation Error List';

ValidationErrorList.propTypes = {
  errors: PropTypes.objectOf(PropTypes.bool).isRequired,
  neutralErrorMessages: PropTypes.arrayOf(PropTypes.string)
};

ValidationErrorList.defaultProps = {
  neutralErrorMessages: []
};

export default ValidationErrorList;
