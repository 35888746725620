import curryRight from 'lodash/curryRight';
import flow from 'lodash/flow';
import get from 'lodash/get';
import values from 'lodash/values';
import some from 'lodash/some';

const curriedGet = curryRight(get)({});
const curriedSome = curryRight(some, 2);
const isTruthy = x => x;

const fieldHasError = (errors, fieldName) => flow([
  curriedGet(fieldName),
  values,
  curriedSome(isTruthy)
])(errors);

export default fieldHasError;
