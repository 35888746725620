import React from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.css';

import ToolSet from '../ToolSet';
import FormFieldHeader from '../FormFieldHeader';
import validation from '@dmm/lib-common/lib/validation';
const { isValidEmail } = validation;
import { isValidGeneralPhoneNumber } from '../../common/util';
import {FAILED_LEAD_VALUE, SENDING_LEAD_VALUE} from '../../common/constants';

const InfoFormSection = (props) => {
  const { text, leadStatus, errorMessages } = props;
  const { register, formState: { isValid, errors } } = useFormContext();

  return (
    <div className={'funnel-info'}>
      <ToolSet
        question={text.loan.label}
        tip={text.loan.tip}
        options={['Yes', 'No']}
        required={true}
        nameId={'info.loan'}/>

      <ToolSet
        question={text.warranty.label}
        tip={text.warranty.tip}
        options={['Yes', 'No']}
        required={true}
        nameId={'info.warranty'}/>

      <ToolSet
        question={text.intentSell.label}
        tip={text.intentSell.tip}
        options={['Yes', 'No']}
        required={false}
        nameId={'info.intentSell'}/>

      <div className={'wrapper-input-funnel'}>
        <FormFieldHeader titleText={'Full Name'} tipText={text.fullName.tip} field="info.fullName"/>
        <input {...register('info.fullName', {required: true })}
          type={'text'}
          id={'info.fullName'}
          placeholder={text.fullName.placeholder}
        />
      </div>

      <div
        className={classNames(
          'wrapper-input-funnel',
          { 'error': errors?.info?.phone?.type === 'isValidGeneralPhoneNumber' }
        )}
      >
        <FormFieldHeader titleText={text.phone.label} tipText={text.phone.tip} field="info.phone"/>
        <input {...register('info.phone', {required: true, validate: { isValidGeneralPhoneNumber } })}
          type={'number'}
          id={'info.phone'}
          placeholder={text.phone.placeholder}
        />
      </div>

      <div
        className={classNames(
          'wrapper-input-funnel',
          { 'error': errors?.info?.email?.type === 'isValidEmail' }
        )}
      >
        <FormFieldHeader titleText={text.email.label} tipText={text.email.tip} field="info.email"/>
        <input {...register('info.email', {required: true, validate: { isValidEmail } })}
          type={'email'}
          id={'info.email'}
          placeholder={text.email.placeholder}
        />
      </div>

      <p>
        By clicking &quot;Submit&quot; you agree to have a Boat Trader representative or affiliate
        reach out via phone, email, or text message regarding this offer. You also agree that your consent
        for direct communication does not create an acceptance or a guarantee of an offer and does not create
        a contract to purchase. See our <a href="https://www.boatsgroup.com/privacy/" target="_blank" rel={'noreferrer'}>Privacy Policy</a> to learn more more about the
        use of data and your rights. You also agree to our <a href="https://www.boatsgroup.com/terms-of-use/" target="_blank" rel={'noreferrer'}>Terms of Use</a>.
      </p>

      { leadStatus === FAILED_LEAD_VALUE && <p className={'message-error'}>{errorMessages.default}</p>}
      <input type="submit"
        className="button-submit-funnel"
        value={leadStatus === SENDING_LEAD_VALUE ? 'Sending...' : 'Submit'}
        disabled={!isValid || leadStatus === SENDING_LEAD_VALUE}
      />

    </div>
  );
};

InfoFormSection.propTypes = {
  text: PropTypes.shape({
    loan: PropTypes.shape({
      label: PropTypes.string,
      tip: PropTypes.string,
    }),
    warranty: PropTypes.shape({
      label: PropTypes.string,
      tip: PropTypes.string,
    }),
    intentSell: PropTypes.shape({
      label: PropTypes.string,
      tip: PropTypes.string,
    }),
    fullName: PropTypes.shape({
      label: PropTypes.string,
      placeholder: PropTypes.string,
      tip: PropTypes.string,
    }),
    phone: PropTypes.shape({
      label: PropTypes.string,
      placeholder: PropTypes.string,
      tip: PropTypes.string,
    }),
    email: PropTypes.shape({
      label: PropTypes.string,
      placeholder: PropTypes.string,
      tip: PropTypes.string,
    })
  }),
  leadStatus: PropTypes.string,
  errorMessages: PropTypes.object
};

export default InfoFormSection;
