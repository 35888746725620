import React from 'react';
import PropTypes from 'prop-types';

export const AltTextContext = React.createContext({
  completedIconAlt: 'completed step',
  incompleteIconAlt: 'incomplete step',
  editIconAlt: 'edit step'
});

const Accordion = (props) => {
  return (
    <AltTextContext.Provider
      value={{
        completedIconAlt: props.completedIconAlt,
        incompleteIconAlt: props.completedIconAlt,
        editIconAlt: props.editIconAlt}}
    >
      <div className="accordion-wrapper">
        {props.children}
      </div>
    </AltTextContext.Provider>
  );
};

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  completedIconAlt: PropTypes.string.isRequired,
  incompleteIconAlt: PropTypes.string.isRequired,
  editIconAlt: PropTypes.string.isRequired
};

export default Accordion;
