import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { getLocations } from '../../common/api';
import BinaryRadio from '../BinaryRadio';
import ToolSet from '../ToolSet';
import FormFieldHeader from '../FormFieldHeader';
import ImageUploader from '../ImageUploader';
import TypeAheadInput from '../TypeAheadInput';
import './styles.css';
import '../../common/form.common.css';
import '../../common/funnelForm.common.css';
import addMoreIcon from './images/add-more.svg';
import backViewIcon from './images/back-view.svg';
import cockpitViewIcon from './images/cockpit-view.svg';
import deckViewIcon from './images/deck-view.svg';
import enginesViewIcon from './images/engines-view.svg';
import frontViewIcon from './images/front-view.svg';
import leftViewIcon from './images/left-view.svg';
import rightViewIcon from './images/right-view.svg';
import { INAPPROPRIATE_ERROR_TYPE, IMAGE_UPLOAD_ERROR_TYPE } from '../../common/constants';
import ErrorMessages from '../ErrorMessages';

const DetailsFormSection = (props) => {
  const { text, onClickNext, locationsEndpoint } = props;

  const { register, watch, formState: { errors, isValid },
    getValues, control } = useFormContext();
  watch();

  const [locations, setLocations] = useState([getValues('details.location') || 'default']);

  useEffect(() => {
    getLocations(getValues('details.location'), locationsEndpoint)
      .then(response => {
        setLocations(response ? response : []);
      });
  }, [getValues('details.location')]);

  return (
    <div className="funnel-form details-form">
      <div className="zipcode-wrapper">
        <FormFieldHeader titleText={text.zipcodeTitle} tipText={text.zipcodeTip} field="details.location"/>

        <Controller
          className="dd-location-input"
          control={control}
          name="details.location"
          defaultValue=""
          rules={{ validate: value => locations.some(location => location === value) }}
          render={({ field: { onChange, value } }) => (
            <TypeAheadInput
              error={!!errors.details && !!errors.details.location}
              list={locations}
              setValue={onChange}
              value={value}
              inputId="details.location"
              placeholderText={text.zipcodePlaceholder}
              blockNonNumeric={true}
            />
          )}
        />
      </div>

      <div className="photos-wrapper">
        <FormFieldHeader titleText={text.photosTitle} tipText={text.photosTip} required={false}/>
        <p className="photos-description">
          {text.photosDescription}
        </p>

        <div className="image-uploaders-wrapper">
          {[
            {
              icon: leftViewIcon,
              title: text.leftViewTitle,
              inputName: 'details.leftView',
              urlName: 'details.leftViewUrl'
            },
            {
              icon: rightViewIcon,
              title: text.rightViewTitle,
              inputName: 'details.rightView',
              urlName: 'details.rightViewUrl'
            },
            {
              icon: frontViewIcon,
              title: text.frontViewTitle,
              inputName: 'details.frontView',
              urlName: 'details.frontViewUrl'
            },
            {
              icon: backViewIcon,
              title: text.backViewTitle,
              inputName: 'details.backView',
              urlName: 'details.backViewUrl'
            },
            {
              icon: enginesViewIcon,
              title: text.enginesViewTitle,
              inputName: 'details.enginesView',
              urlName: 'details.enginesViewUrl'
            },
            {
              icon: cockpitViewIcon,
              title: text.cockpitViewTitle,
              inputName: 'details.cockpitView',
              urlName: 'details.cockpitViewUrl'
            },
            {
              icon: deckViewIcon,
              title: text.deckViewTitle,
              inputName: 'details.deckView',
              urlName: 'details.deckViewUrl'
            }].map((uploaderProps, index) => (
            <ImageUploader
              key={index}
              icon={uploaderProps.icon}
              title={uploaderProps.title}
              inputName={uploaderProps.inputName}
              urlName={uploaderProps.urlName}
              endpoint={props.imageUploadEndpoint}
            />
          ))}
          <ImageUploader
            icon={addMoreIcon}
            inputName="details.additionalImages"
            urlName="details.additionalImagesUrl"
            endpoint={props.imageUploadEndpoint}
          />
        </div>
        <p className="photos-additional-info">
          {text.photosAdditionalInfo}
        </p>
        {(
          errors.details
          && Object.values(errors.details).some(x => x.type === INAPPROPRIATE_ERROR_TYPE)
        ) && (
          <ErrorMessages errorMessages={[text.inappropriateErrorMessage]}/>
        )}
        {(
          errors.details
          && Object.values(errors.details).some(x => x.type === IMAGE_UPLOAD_ERROR_TYPE)
        ) && (
          <ErrorMessages errorMessages={[text.uploadErrorMessage]}/>
        )}
      </div>

      <div className="saltwater-wrapper">
        <BinaryRadio
          name="details.saltwater"
          required={true}
          id={{yes: 'saltwaterYes', no: 'saltwaterNo'}}
          value={{yes: 'yes', no: 'no'}}
          text={{
            title: text.saltwaterTitle,
            tip: text.saltwaterTip,
            yes: text.saltwaterYes,
            no: text.saltwaterNo
          }}
        />
      </div>

      <div className="condition-wrapper">
        <ToolSet
          question={text.conditionTitle}
          tip={text.conditionTip}
          nameId="details.boatCondition"
          options={text.conditionOptions}
          required={true}
        />
      </div>

      <div className="problems-wrapper">
        <FormFieldHeader titleText={text.problemsTitle} tipText={text.problemsTip} field="details.problems"/>

        <textarea
          id="details.problems"
          {...register('details.problems', {
            required: true
          })}
          placeholder={text.problemsPlaceholder}
        />

      </div>

      <input type="button"
        data-testid="details-next"
        className="button-submit-funnel"
        onClick={onClickNext}
        value={text.next}
        disabled={!isValid}
      />
    </div>
  );
};

DetailsFormSection.propTypes = {
  onClickNext: PropTypes.func,
  imageUploadEndpoint: PropTypes.string.isRequired,
  locationsEndpoint: PropTypes.string.isRequired,
  text: PropTypes.shape({
    zipcodeTitle: PropTypes.string.isRequired,
    zipcodePlaceholder: PropTypes.string.isRequired,
    zipcodeTip: PropTypes.string.isRequired,
    photosTitle: PropTypes.string,
    photosTip: PropTypes.string.isRequired,
    photosDescription: PropTypes.string.isRequired,
    leftViewTitle: PropTypes.string.isRequired,
    rightViewTitle: PropTypes.string.isRequired,
    frontViewTitle: PropTypes.string.isRequired,
    backViewTitle: PropTypes.string.isRequired,
    enginesViewTitle: PropTypes.string.isRequired,
    cockpitViewTitle: PropTypes.string.isRequired,
    deckViewTitle: PropTypes.string.isRequired,
    photosAdditionalInfo: PropTypes.string.isRequired,
    saltwaterTitle: PropTypes.string.isRequired,
    saltwaterTip: PropTypes.string.isRequired,
    saltwaterYes: PropTypes.string.isRequired,
    saltwaterNo: PropTypes.string.isRequired,
    conditionTitle: PropTypes.string.isRequired,
    conditionTip: PropTypes.string.isRequired,
    conditionOptions: PropTypes.array.isRequired,
    problemsTitle: PropTypes.string.isRequired,
    problemsPlaceholder: PropTypes.string.isRequired,
    problemsTip: PropTypes.string.isRequired,
    next: PropTypes.string.isRequired,
    inappropriateErrorMessage: PropTypes.string.isRequired,
    uploadErrorMessage: PropTypes.string.isRequired
  }),
};

export default DetailsFormSection;
