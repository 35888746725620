import validatePassword from '../../common/util/validatePassword';
import { EMAIL_REGEX } from '../../common/Constants.common';

export const validateEmailWith = (errorMessages) => (values) => {
  // errors must be {[string]: boolean} objects
  let errors = {
    email: {}
  };

  if (!values.email) {
    errors.email[errorMessages.email.required] = true;
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email[errorMessages.email.invalid] = true;
  }

  return errors;
};

export const validateNewPasswordWith = (errorMessages) => (values) => {
  // errors must be {[string]: boolean} objects
  let errors = {
    code: {},
    password: {},
    confirmPassword: {}
  };

  if (!values.password) {
    errors.password[errorMessages.password.required] = true;
  } else {
    errors = validatePassword(values, errors, errorMessages);
  }

  if (!values.confirmPassword) {
    errors.confirmPassword[errorMessages.confirmPassword.required] = true;
  } else if (values.confirmPassword !== values.password) {
    errors.confirmPassword[errorMessages.confirmPassword.noMatch] = true;
  }

  if (!values.code) {
    errors.code[errorMessages.code.required] = true;
  }

  return errors;
};
