import React from 'react';
import PropTypes from 'prop-types';
import appleImage from './apple.svg';
import googleImage from './google.svg';
import {
  socialSignInWithApple,
  socialSignInWithGoogle
} from '../../common/util/amplify';
import { setTracking } from '../../common/util/dataLayer';
import { setRedirect } from '../../common/util/redirectHelper';

import './SocialSignin.css';

const SocialSignin = (props) => {
  const { trackingSignInWithApple, trackingSignInWithGoogle, defaultView, socialAuthentication: {apple, google} } = props;

  const handleSignInWithAppleClick = (e) => {
    e.preventDefault();

    setTracking(trackingSignInWithApple);
    setRedirect({ defaultView, isFederated: true });
    socialSignInWithApple();
  };

  const handleSignInWithGoogleClick = (e) => {
    e.preventDefault();

    setTracking(trackingSignInWithGoogle);
    setRedirect({ defaultView, isFederated: true });
    socialSignInWithGoogle();
  };

  return (
    <div className="social">
      {apple &&
        <button className="social-signin-button social-signin-spacing-bottom" onClick={handleSignInWithAppleClick}>
          <img className="social-signin-img" alt="Apple sign in" src={appleImage}/>
        </button>
      }

      {google &&
        <button className="social-signin-button" onClick={handleSignInWithGoogleClick}>
          <img className="social-signin-img" alt="Google sign in" src={googleImage}/>
        </button>
      }
    </div>
  );
};

SocialSignin.displayName = 'Social Buttons';
SocialSignin.propTypes = {
  socialAuthentication: PropTypes.shape({
    apple: PropTypes.bool,
    google: PropTypes.bool
  }),
  defaultView: PropTypes.object,
  trackingSignInWithApple: PropTypes.object,
  trackingSignInWithGoogle: PropTypes.object
};

export default SocialSignin;
