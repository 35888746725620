import { MIN_PASSWORD_LENGTH } from '../../common/Constants.common';
import { mergeDeepLeft } from 'ramda';

const validatePassword = (values, errors, errorMessages) => {
  const newErrors = { password: {} };

  newErrors.password[errorMessages.password.length] = (values.password.length < MIN_PASSWORD_LENGTH);
  newErrors.password[errorMessages.password.digit] = (!/\d/.test(values.password));
  newErrors.password[errorMessages.password.lowercase] = (!/[a-z]/.test(values.password));
  newErrors.password[errorMessages.password.uppercase] = (!/[A-Z]/.test(values.password));

  return mergeDeepLeft(errors, newErrors);
};

export default validatePassword;
