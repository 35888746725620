import React from 'react';
import PropTypes from 'prop-types';
import successIcon from '../../../common/images/success.svg';

const Confirmation = (props) => {

  const { text, backFunction } = props;

  return (
    <div className="sign-up-success">
      <div className="user-form-message">
        <p>
          <a className="link-button" onClick={backFunction} >
            { text.headerInformation.link }
          </a>
          <span>{ text.headerInformation.message1 }</span>
        </p>
      </div>
      <img alt={ text.img.successAlt} src={successIcon} />
    </div>
  );
};

Confirmation.displayName = 'Confirmation';

Confirmation.propTypes = {
  text: PropTypes.shape({
    headerInformation: PropTypes.shape({
      link: PropTypes.string,
      message1: PropTypes.string,
    }),
    img: PropTypes.shape({
      successAlt: PropTypes.string
    })
  }),
  backFunction: PropTypes.func
};

Confirmation.defaultProps = {
  text: {
    headerInformation: {
      link: 'Sign In',
      message1: ' to your new Boat Trader account.',
    },
    img: {
      successAlt: 'successful sign up'
    }
  }
};

export default Confirmation;
