import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';
import '../ForgottenPasswordFlow.css';
import '../../../common/Form.common.css';
import {validateEmailWith} from '../validateWith';
import useForm from '../../../common/hooks/useForm';
import ValidationErrorList from '../../ValidationErrorList';
import fieldHasError from '../../../common/util/fieldHasError';
import NewPasswordForm from '../NewPasswordForm/index';

const VerifyUserNameForm = (props) => {
  const {text, backFunction, sent} = props;

  const { handleChange, handleSubmit, values, allErrors } = useForm(
    props.submitForm,
    validateEmailWith,
    props.clientErrorMessages,
    {
      email: {
        defaultValue: '',
        serverErrors: props.serverErrors.email
      }
    }
  );

  return (
    <div>
      {!sent &&
        <form onSubmit={handleSubmit} noValidate>
          <div className="user-form-message">
            {text.forgottenPasswordMessage}
          </div>
          <fieldset className={classNames('user-form-input', {'user-form-error': fieldHasError(allErrors, 'email')})}>
            <label htmlFor="forgot-signup-email">
              { props.text.emailLabel }
            </label>
            <input
              id="forgot-signup-email"
              autoComplete="email"
              type="email"
              name="email"
              tabIndex="100"
              placeholder={text.emailPlaceholder}
              value={values.email}
              onChange={handleChange}
            />
            <ValidationErrorList errors={get(allErrors, 'email', {})}/>
            <div className="user-form-button-container">
              <button tabIndex="101" data-testid="forgotten-password-reset-button" disabled={values.email.length === 0}>{ text.submitButton }</button>
            </div>
            <div className="message">
              <p>
                <span>{text.backMessage}</span>
                <a
                  className="link-button"
                  data-testid="forgotten-password-signin-button"
                  onClick={backFunction}
                >
                  {text.backButton}
                </a>
              </p>
            </div>
          </fieldset>
        </form>}
      {sent &&
        <NewPasswordForm {...props.newPasswordFormProps}/>}
    </div>
  );
};

VerifyUserNameForm.displayName = 'Forgotten Password Form';

VerifyUserNameForm.propTypes = {
  text: PropTypes.shape({
    forgottenPasswordMessage: PropTypes.string,
    emailLabel: PropTypes.string,
    emailPlaceholder: PropTypes.string,
    submitButton: PropTypes.string,
    backMessage: PropTypes.string,
    backButton: PropTypes.string,
  }),
  submitForm: PropTypes.func,
  sent: PropTypes.bool,
  backFunction: PropTypes.func,
  clientErrorMessages: PropTypes.shape({
    email: PropTypes.shape({
      required: PropTypes.string,
      invalid: PropTypes.string
    }),
  }),
  serverErrors: PropTypes.shape({
    email: PropTypes.objectOf(PropTypes.bool),
  }),
  newPasswordFormProps: PropTypes.object,
};

VerifyUserNameForm.defaultProps = {
  text: {
    forgottenPasswordMessage: 'You will have to create a new password to sign into your account.',
    emailLabel: 'Email',
    emailPlaceholder: 'Enter Email',
    submitButton: 'Reset',
    backMessage: 'Know your password?',
    backButton: 'Sign In'
  },
  sent: false,
  // eslint-disable-next-line no-unused-vars
  submitForm: (values, clientErrors) => {},
  backFunction: () => {},
  clientErrorMessages: {
    email: {
      required: 'Email is required',
      invalid: 'Email address is invalid'
    },
  },
  serverErrors: {
    email: {}
  }
};

export default VerifyUserNameForm;
