import React, {useEffect,useState} from 'react';
import _ from 'lodash';
import axios from 'axios';
import {useFormReducer} from './stateHandlers';
import PropTypes from 'prop-types';
import './styles.css';
import '../../common/form.common.css';
import SuccessMessage from '../SuccessMessage';
import ErrorMessages from '../ErrorMessages';
import FunnelMasterComponent from '../FunnelMasterComponent';
import {getFormBody} from '../../common/util';
import {getMakes, getModels} from '../../common/api';
import TypeAheadInput from '../TypeAheadInput';
import { updateSession } from '../../common/sessionHelper';

const Form = (props) => {

  const [sessionData, setSessionData] = useState(props.session);
  const [salesforceId, setSalesforceId] = useState();

  const {
    title,
    year,
    make,
    model,
    fullName,
    length,
    phone,
    email,
    zip,
    contactAllowed,
    submitButton,
    loadingMessage,
    formSubmitted,
  } = props.formData;

  const {
    setBoatYear,
    setBoatMake,
    setBoatModel,
    setFullName,
    setPhone,
    setEmail,
    setZip,
    setLength,
    setContactAllowed,
    resetForm,
    validateInput,
    setLoading,
    setShowFunnel,
    setFoundMakes,
    setFoundModels,
    state
  } = useFormReducer(sessionData);
  let contact = state.contact;
  let boat = state.boat;

  useEffect(() => {
    resetForm(formSubmitted);
    setSessionData(props.session);
    getMakes(props.endpoints.makesEndpoint).then(makes => {
      setFoundMakes(makes);
    });
    if (props.session && props.session.isSession) {
      setShowFunnel(true);
    }
    return () => {
      resetForm(formSubmitted);
      validateInput([]);
      setFoundMakes([]);
      setFoundModels([]);
    };
  }, [props.session]);

  const findModels = () => {
    getModels(state.boat.make, state.boat.year, props.endpoints.modelsEndpoint ).then(models => {
      setFoundModels(models);
    });
  };

  const displayErrors = () => {
    const data = {
      ...state.contact,
      ...state.boat
    };

    const errors = Object.keys(data).filter((value) => data[value].error || data[value].value === false || data[value].value === '');
    return errors.map((error) => props.errorMessages[error]);
  };

  const sendLead = () => {
    setLoading(!state.loading);
    props.trackingEvent && props.trackingEvent('driveway direct', 'form click');
    axios.post(props.endpoints.leadEndpoint, getFormBody(state))
      .then(() => resetForm(!state.formSubmitted))
      .catch(() => {
        validateInput([props.errorMessages.default]);
        setLoading(state.loading);
      });
  };

  const createLead = async() => {
    setLoading(!state.loading);
    try {
      const response = await axios.post(props.endpoints.leadCreationEndpoint, getFormBody(state));
      const data = response.data;
      const { id, drivewayId } = data;
      return {
        id,
        drivewayId
      };
    } catch (error) {
      validateInput([props.errorMessages.default]);
      setLoading(state.loading);
      throw new Error(error);
    }
  };

  const submit = async (e) => {
    console.log('SUBMIT');
    e.preventDefault();
    const errors = displayErrors();
    validateInput(errors);
    if (errors.length === 0) {
      if (props.funnelMode) {
        let drivewayId, salesforceIdResponse;
        let funnelData = _.get(sessionData, 'funnelData');
        let session;
        try {
          const response = await createLead();
          console.log('RESPONSE - createLead', response);
          salesforceIdResponse = response.id;
          drivewayId = response.drivewayId;
          console.log('SF DID', salesforceIdResponse, drivewayId);
          setSalesforceId(salesforceIdResponse);
          session = updateSession({contact, boat, salesforceId: salesforceIdResponse}, funnelData, 0);
          props.trackingEvent && props.trackingEvent('driveway direct', '01 - get started form click', drivewayId);
        } catch (error) {
          return;
        }
        setSessionData(session);
        setShowFunnel(true);
        props.showStaticData && props.showStaticData(false);
      } else {
        sendLead();
      }
    }
    return state;
  };

  return (
    state.showFunnel
      ? <FunnelMasterComponent
        {...props.funnelMasterComponentProps}
        formProps={{salesforceId, contact, boat, endpoint: props.endpoint,
          endpoints: props.endpoints, foundMakes: state.foundMakes}}
        endpoints={props.endpoints}
        errorMessages={props.errorMessages}
        trackingEvent={props.trackingEvent}
        sessionData={sessionData}/>
      :
      <div className={'form-dd container-form'}>
        <form onSubmit={(e) => submit(e)}
          className={state.formSubmitted ? 'submitted' : 'form'}
          aria-label={'Boat information'}
          autoComplete={'on'}>
          <div className={'title'}>{title}</div>
          <div className={'container-input'}>
            <div className={'container-input-user'}>
              <div className={`dd-wrapper-input ${contact.fullName.error && 'error-validation'}`}>
                <input id="fullName"
                  type="text"
                  value={contact.fullName.value}
                  onChange={({target}) => setFullName(target.value)}
                  disabled={state.loading ? 'disabled' : ''}
                  maxLength={'100'}
                  required
                  autoComplete={'name'}/>
                {contact.fullName.value === '' &&
                  <label htmlFor={'fullName'}>{fullName}</label>
                }
              </div>
              <div className={`dd-wrapper-input ${contact.email.error && 'error-validation'}`}>
                <input id="email"
                  type="email"
                  value={contact.email.value}
                  onChange={({target}) => setEmail(target.value)}
                  disabled={state.loading ? 'disabled' : ''}
                  maxLength={'100'}
                  required
                  autoComplete={'email'}/>
                {contact.email.value === '' &&
                  <label htmlFor={'email'}>{email}</label>
                }
              </div>
              <div className={`dd-wrapper-input ${contact.phone.error && 'error-validation'}`}>
                <input id="phone"
                  type="text"
                  value={contact.phone.value}
                  onChange={({target}) => setPhone(target.value)}
                  disabled={state.loading ? 'disabled' : ''}
                  maxLength={'100'}
                  required
                  autoComplete={'tel'}/>
                {contact.phone.value === '' &&
                  <label htmlFor={'phone'}>{phone}</label>
                }
              </div>
              <div className={`dd-wrapper-input ${contact.zip.error && 'error-validation'}`}>
                <input id="zip"
                  type="text"
                  value={contact.zip.value}
                  onChange={({target}) => setZip(target.value)}
                  disabled={state.loading ? 'disabled' : ''}
                  maxLength={'10'}
                  required
                  autoComplete={'postal-code'}/>
                {contact.zip.value === '' &&
                  <label htmlFor={'zip'}>{zip}</label>
                }
              </div>

            </div>
            <div className={'container-input-boat'}>
              <div className={`dd-wrapper-input ${boat.year.error && 'error-validation'}`}>
                <input id="year"
                  className="form-year"
                  data-testid="year"
                  type="number"
                  value={boat.year.value}
                  onChange={({target}) => setBoatYear(target.value)}
                  disabled={state.loading ? 'disabled' : ''}
                  min="1800"
                  max={new Date().getFullYear()}
                  required
                  autoComplete={'year'}/>
                {!boat.year.value &&
                  <label htmlFor={'year'}>{year}</label>
                }
              </div>
              {/* Length */}
              <div className={`dd-wrapper-input ${boat.length.error && 'error-validation'}`}>
                <input id="length"
                  type="number"
                  value={boat.length.value}
                  onChange={({target}) => setLength(target.value)}
                  disabled={state.loading ? 'disabled' : ''}
                  maxLength={'5'}
                  required
                  autoComplete={'length'}/>
                {boat.length.value === '' &&
                  <label htmlFor={'length'}>{length}</label>
                }
              </div>
              {/* Make */}
              <div
                className={'wrapper-make'}
                onBlur={findModels}>
                <TypeAheadInput
                  inputId={'make'}
                  value={boat.make.value}
                  error={boat.make.error}
                  setValue={setBoatMake}
                  loading={state.loading}
                  defaultListOption={'custom/other'}
                  list={state.foundMakes}
                  label={make}
                  minSearchLength={3}/>
              </div>

              {/* Model */}
              <TypeAheadInput
                inputId={'model'}
                value={boat.model.value}
                error={boat.model.error}
                setValue={setBoatModel}
                loading={state.loading}
                list={state.foundModels}
                label={model}/>
            </div>
          </div>
          <label htmlFor="contactAllowed" className={'container-checkbox'}>
            <div className={'wrapper-checkbox'}>
              <input id="contactAllowed"
                type="checkbox"
                data-testid={'contactAllowed'}
                checked={contact.contactAllowed.value}
                onChange={() => setContactAllowed(!contact.contactAllowed.value)}
                disabled={state.loading ? 'disabled' : ''}/>
              <span className="checkbox-styled"/>
            </div>
            <div className={'wrapper-checkbox'}>
              {contactAllowed}
            </div>
          </label>

          {state.errorMessages.length > 0 && <ErrorMessages errorMessages={state.errorMessages}/>}
          <input type="submit"
            className={'button-submit'}
            value={state.loading ? loadingMessage : submitButton}
            disabled={state.loading ? 'disabled' : ''}/>
        </form>
        {state.formSubmitted && <SuccessMessage {...props.successMessageData}/>}

      </div>
  );
};

Form.propTypes = {
  formData: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    fullName: PropTypes.string,
    zip: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    year: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    length: PropTypes.string,
    contactAllowed: PropTypes.string,
    submitButton: PropTypes.string,
    formSubmitted: PropTypes.bool,
    loading: PropTypes.bool,
    loadingMessage: PropTypes.string,
    funnelMode: PropTypes.bool
  }),
  successMessageData: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string
  }),
  errorMessages: PropTypes.object,
  endpoint: PropTypes.string,
  endpoints: PropTypes.object,
  trackingEvent: PropTypes.func,
  showStaticData: PropTypes.func,
  funnelMode: PropTypes.bool,
  funnelMasterComponentProps: PropTypes.object.isRequired,
  session: PropTypes.object
};

export default Form;
