import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import without from 'lodash/without';
import classNames from 'classnames';
import '../ForgottenPasswordFlow.css';
import '../../../common/Form.common.css';
import {validateNewPasswordWith} from '../validateWith';
import useForm from '../../../common/hooks/useForm';
import ValidationErrorList from '../../ValidationErrorList';
import ClientValidationErrorList from '../../ClientValidationErrorList';
import fieldHasError from '../../../common/util/fieldHasError';
import ReactPinField from 'react-pin-field';
import classnames from 'classnames';

const NewPasswordForm = (props) => {
  const ref = useRef(null);

  const {text, resendFunction} = props;

  const { handleChange, handleSubmit, values, allErrors, clientErrors, serverErrors } = useForm(
    props.submitForm,
    validateNewPasswordWith,
    props.clientErrorMessages,
    {
      code: {
        defaultValue: '',
        serverErrors: props.serverErrors.code,
      },
      password: {
        defaultValue: '',
        serverErrors: props.serverErrors.password,
      },
      confirmPassword: {
        defaultValue: '',
        serverErrors: props.serverErrors.confirmPassword,
      },
    },
  );

  useEffect(() => {
    ref && ref.current && ref.current[0].focus();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="user-form-message">
          {text.newPasswordMessage}
        </div>
        <fieldset className={classNames('user-form-input', {'user-form-error': fieldHasError(allErrors, 'code')})}>
          <label htmlFor="code">{text.code}</label>
          <div className={classnames('code-input', {error: Object.keys(serverErrors.code).length !== 0})}>
            <ReactPinField
              id="code"
              ref={ref}
              inputMode="numeric"
              length={6}
              validate="0123456789"
              onChange={(value) => {handleChange({ target: {name: 'code', value }});}}
              type="number"
              className="pin-field"
            />
          </div>
          <ValidationErrorList errors={get(allErrors, 'code', {})}/>
        </fieldset>
        <fieldset className={classNames('user-form-input', {'user-form-error': fieldHasError(allErrors, 'password')})}>
          <label htmlFor="new-password">{text.password}</label>
          <input
            id="new-password"
            name="password"
            type="password"
            tabIndex="100"
            value={values.password}
            placeholder={text.passwordPlaceholder}
            onChange={handleChange}
            required
          />
          <ClientValidationErrorList
            errors={get(clientErrors, 'password', {})}
            neutralErrorMessages={without(
              Object.values(props.clientErrorMessages.password),
              props.clientErrorMessages.password.required
            )}
          />
          <ValidationErrorList errors={serverErrors.password}/>
        </fieldset>
        <fieldset className={classNames('user-form-input', {'user-form-error': fieldHasError(allErrors, 'confirmPassword')})}>
          <label htmlFor="confirm-password">{text.confirmPassword}</label>
          <input
            id="confirm-password"
            name="confirmPassword"
            type="password"
            tabIndex="101"
            value={values.confirmPassword}
            placeholder={text.confirmPasswordPlaceholder}
            onChange={handleChange}
            required
          />
          <ValidationErrorList errors={get(allErrors, 'confirmPassword', {})}/>
        </fieldset>
        <div className="user-form-button-container">
          <button
            tabIndex="102"
            disabled={values.password.length === 0 || values.confirmPassword.length === 0 || values.code.length !== 6}>
            { props.text.submitPassword }
          </button>
        </div>
        <div className="message">
          <p>
            <span>{text.terms}</span>
            <a
              href={props.termsHref}
              target="_blank"
              rel="noreferrer"
              className="link-button"
              tabIndex="104"
            >
              {text.termsLink}
            </a>
          </p>
        </div>
        <div className="message">
          <p>
            <span>{text.resendCodeMessage}</span>
            <a className="link-button" onClick={resendFunction} tabIndex="105">{text.resendButton}</a>
          </p>
        </div>
      </form>
    </div>
  );
};

NewPasswordForm.displayName = 'New Password Form';

NewPasswordForm.propTypes = {
  text: PropTypes.shape({
    code: PropTypes.string,
    confirmPassword: PropTypes.string,
    confirmPasswordPlaceholder: PropTypes.string,
    newPasswordMessage: PropTypes.string,
    password: PropTypes.string,
    passwordPlaceholder: PropTypes.string,
    resendCodeMessage: PropTypes.string,
    resendButton: PropTypes.string,
    submitPassword: PropTypes.string,
    terms: PropTypes.string,
    termsLink: PropTypes.string
  }),
  termsHref: PropTypes.string,
  clientErrorMessages: PropTypes.shape({
    code: PropTypes.shape({
      required: PropTypes.string,
    }),
    password: PropTypes.shape({
      required: PropTypes.string,
      length: PropTypes.string,
      digit: PropTypes.string,
      lowercase: PropTypes.string,
      uppercase: PropTypes.string
    }),
    confirmPassword: PropTypes.shape({
      required: PropTypes.string,
      noMatch: PropTypes.string,
    }),
  }),
  serverErrors: PropTypes.shape({
    code: PropTypes.objectOf(PropTypes.bool),
    password: PropTypes.objectOf(PropTypes.bool),
    confirmPassword: PropTypes.objectOf(PropTypes.bool),
  }),
  resendFunction: PropTypes.func,
  submitForm: PropTypes.func,
};

NewPasswordForm.defaultProps = {
  text: {
    code: 'Verification Code',
    confirmPassword: 'Confirm Password',
    confirmPasswordPlaceholder: 'Re-Enter Password',
    newPasswordMessage: 'You will receive an email shortly with a code to reset your password.',
    password: 'New Password',
    passwordPlaceholder: 'Enter New Password',
    resendCodeMessage: 'Did not receive the code?',
    resendButton: 'Resend',
    submitPassword: 'Submit',
    terms: 'By submitting, you accept Boats Group’s',
    termsLink: 'Terms'
  },
  termsHref: 'https://www.boatsgroup.com/terms-of-use/',
  clientErrorMessages: {
    code: {
      required: 'Code is required',
    },
    password: {
      required: 'Password is required',
      length: 'At least 8 characters',
      digit: 'At least one number',
      lowercase: 'At least one lowercase letter',
      uppercase: 'At least one uppercase letter'
    },
    confirmPassword: {
      required: 'Confirm Password is required',
      noMatch: 'Confirm password does not match new password'
    },
  },
  serverErrors: {
    code: {},
    password: {},
    confirmPassword: {}
  },
  resendFunction: () => {},
  // eslint-disable-next-line no-unused-vars
  submitForm: (values, clientErrors) => {},
};

export default NewPasswordForm;
