import { useRef } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

// useDidMountDeepCompareEffect is only necessary if the dependencies contain an object
// that needs to be deeply compared (see
// https://stackoverflow.com/a/59322710/12275630)
const useDidMountDeepCompareEffect = (func, deps) => {
  const didMount = useRef(false);

  useDeepCompareEffect(() => {
    if (didMount.current) {func();}
    else {didMount.current = true;}
  }, deps);
};

export default useDidMountDeepCompareEffect;
