import {useReducer} from 'react';
import validation from '@dmm/lib-common/lib/validation';
import { compareSessions } from '../../common/sessionHelper';

const actions = {
  setBoatYear: 'SET_BOAT_YEAR',
  setBoatMake: 'SET_BOAT_MAKE',
  setBoatModel: 'SET_BOAT_MODEL',
  setFullName: 'SET_FULL_NAME',
  setPhone: 'SET_PHONE',
  setEmail: 'SET_EMAIL',
  setZip: 'SET_ZIP',
  setContactAllowed: 'SET_CONTACT_ALLOWED',
  resetForm: 'RESET_FORM',
  validateInput: 'VALIDATE_INPUT',
  setLoading: 'SET_LOADING',
  setShowFunnel: 'SET_SHOW_FUNNEL',
  setFoundMakes: 'SET_FOUND_MAKES',
  setFoundModels: 'SET_FOUND_MODELS',
  setLength: 'SET_LENGTH'
};

const formReducer = (state, action) => {
  let filteredName;
  let foundMakes;
  let length;
  switch (action.type) {
  case 'SET_BOAT_YEAR':
    return {
      ...state,
      boat: {
        ...state.boat,
        year: {
          value: action.value,
          error: action.value && (action.value < 1800 || action.value > new Date().getFullYear())
        }
      }
    };
  case 'SET_BOAT_MAKE':
    foundMakes = [...state.foundMakes, 'custom/other'];
    return {
      ...state,
      boat: {
        ...state.boat,
        make: {
          value: action.value,
          error: foundMakes.indexOf(action.value) === -1
        },
        model: {
          value: '',
          error: false
        }
      }
    };
  case 'SET_BOAT_MODEL':
    return {
      ...state,
      boat: {
        ...state.boat,
        model: {
          value: action.value,
          error: action.value === ''
        }
      }
    };
  case 'SET_LENGTH':
    length = action.value;
    return {
      ...state,
      boat: {
        ...state.boat,
        length: {
          value: length,
          error: !length || !/^(?:\d{1,3})?$/.test(length) || length < 1
        }
      }
    };
  case 'SET_FULL_NAME':
    filteredName = action.value.replace(/\s+/g, ' ');
    return {
      ...state,
      contact: {
        ...state.contact,
        fullName: {
          value: filteredName,
          error: !validation.isValidName(filteredName)
        }
      }
    };
  case 'SET_PHONE':
    return {
      ...state,
      contact: {
        ...state.contact,
        phone: {
          value: action.value,
          error: !validation.isValidPhoneNumber(action.value)
        }
      }
    };
  case 'SET_EMAIL':
    return {
      ...state,
      contact: {
        ...state.contact,
        email: {
          value: action.value,
          error: !validation.isValidEmail(action.value)
        }
      }
    };
  case 'SET_ZIP':
    return {
      ...state,
      contact: {
        ...state.contact,
        zip: {
          value: action.value,
          error: !validation.isValidUsZipCode(action.value)
        }
      }
    };
  case 'SET_CONTACT_ALLOWED':
    return {
      ...state,
      contact: {
        ...state.contact,
        contactAllowed: {
          value: action.value,
          error: !action.value
        }
      }
    };
  case 'RESET_FORM':
    return {
      ...initialState,
      formSubmitted: action.value
    };
  case 'VALIDATE_INPUT':
    return {
      ...state,
      errorMessages: action.value
    };
  case 'SET_LOADING':
    return {
      ...state,
      loading: action.value,
    };
  case 'SET_SHOW_FUNNEL':
    return {
      ...state,
      showFunnel: action.value,
    };
  case 'SET_FOUND_MAKES':
    return {
      ...state,
      foundMakes: action.value,
    };
  case 'SET_FOUND_MODELS':
    return {
      ...state,
      foundModels: action.value
    };
  }
};

const initialState = {
  boat: {
    year: {
      value: '',
      error: false
    },
    make: {
      value: '',
      error: false
    },
    model: {
      value: '',
      error: false
    },
    length: {
      value: '',
      error: false
    }
  },
  contact: {
    fullName: {
      value: '',
      error: false
    },
    email: {
      value: '',
      error: false
    },
    phone: {
      value: '',
      error: false
    },
    zip: {
      value: '',
      error: false
    },
    contactAllowed: {
      value: false,
      error: false
    },
  },
  formSubmitted: false,
  loading: false,
  errorMessage: true,
  errorMessages: [],
  showFunnel: false,
  foundMakes: [],
  foundModels: []
};

export const useFormReducer = (session) => {
  if (session && session.formProps) {

    const sessionBoat = session.formProps.boat;
    const initialBoat = initialState.boat;

    if (!compareSessions(initialBoat, sessionBoat)) {
      initialState.boat = session.formProps.boat;
      initialState.contact = session.formProps.contact;
    }
  }
  const [state, dispatch] = useReducer(formReducer, initialState);

  return {
    setBoatYear: (value) => dispatch({type: actions.setBoatYear, value}),
    setBoatMake: (value) => dispatch({type: actions.setBoatMake, value}),
    setBoatModel: (value) => dispatch({type: actions.setBoatModel, value}),
    setFullName: (value) => dispatch({type: actions.setFullName, value}),
    setPhone: (value) => dispatch({type: actions.setPhone, value}),
    setEmail: (value) => dispatch({type: actions.setEmail, value}),
    setZip: (value) => dispatch({type: actions.setZip, value}),
    setContactAllowed: (value) => dispatch({type: actions.setContactAllowed, value}),
    resetForm: (value) => dispatch({type: actions.resetForm, value}),
    validateInput: (value) => dispatch({type: actions.validateInput, value}),
    setLoading: (value) => dispatch({type: actions.setLoading, value}),
    setShowFunnel: (value) => dispatch({type: actions.setShowFunnel, value}),
    setFoundMakes: (value) => dispatch({type: actions.setFoundMakes, value}),
    setFoundModels: (value) => dispatch({type: actions.setFoundModels, value}),
    setLength: (value) => dispatch({type: actions.setLength, value}),
    state
  };
};
