export const SESSION_STORAGE_KEY = {
  REDIRECT_URL: 'userflow_redirectUrl',
  OAUTH_SIGN_OUT: 'userflow_oauthSignInOutRedirectUrl'
};

/**
 * redirect
 * @description Redirect page based on value in sessionStorage
 * @param {string} sessionStorageKey
 */
export const redirect = (sessionStorageKey) => {
  const url = window.sessionStorage.getItem(sessionStorageKey);

  if (url) {
    window.sessionStorage.removeItem(sessionStorageKey);
    window.location.href = url;
  }
};

export const redirectOauthSignOut = (sessionStorageKey) => {
  redirect(sessionStorageKey);
};

export const setRedirect = ({ defaultView, isFederated }) => {
  const { autoOpen, shouldRedirectToHomePage } = defaultView;
  let redirectUrl;
  const homepageUrl = window.location.origin;

  try {
    redirectUrl = window.sessionStorage.getItem(SESSION_STORAGE_KEY.REDIRECT_URL);
  } catch (err) {
    console.error(err);
  }

  if (!redirectUrl && autoOpen && shouldRedirectToHomePage) {
    // If sessionStorage has no redirectUrl and the modal is open by default and
    // it should redirect to Homepage, set redirectUrl to Home page.
    window.sessionStorage.setItem(SESSION_STORAGE_KEY.REDIRECT_URL, homepageUrl);
  } else if (!redirectUrl && !shouldRedirectToHomePage && isFederated) {
    // If sessionStorage has no redirectUrl and it shouldn't redirect to Homepage,
    // set redirectUrl to the current page.
    window.sessionStorage.setItem(SESSION_STORAGE_KEY.REDIRECT_URL, window.location.href);
  }

};

