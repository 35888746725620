import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { setTracking, modalOpenSignInTab, modalOpenNewAccountTab  } from '../../common/util/dataLayer';

import './Tabs.css';

const Tabs = (props) => {

  const [ activeTab, setActiveTab ] = useState(props.initialTabSelection || props.children[0].props.label);
  const tabRef = useRef(null);

  const handleTracking = (tabName) => {
    const tab = {
      'signin-tab': modalOpenSignInTab,
      'signup-tab': modalOpenNewAccountTab,
    };

    if (tab[tabName]) {
      setTracking(tab[tabName]);
    }
  };

  const tabOnClick = (tab) => {
    handleTracking(tab.target.getAttribute('data-testid'));
    props.onTabChange();
    setActiveTab(tab.target.textContent);
  };

  const setSlider = (activeTab) => {
    const activeTabIndex = props.children.findIndex((x) => x.props.label === activeTab);
    const activeTabWidth = tabRef.current.getElementsByClassName('tab-list-active')[0].getBoundingClientRect().width;
    let transitionDistance = 0;

    for (let i = 0; activeTabIndex > i; i++) {
      transitionDistance += tabRef.current.getElementsByClassName('tab-list-item')[i].getBoundingClientRect().width;
    }

    tabRef.current.getElementsByClassName('tab-slider')[0].style.width = `${activeTabWidth}px`;
    tabRef.current.getElementsByClassName('tab-slider')[0].style.transform = `translateX(${transitionDistance}px)`;
  };

  useEffect(() => {
    if (!props.children.every(child => child.props.label)) {
      // eslint-disable-next-line no-console
      throw 'Children of Tabs component require a "label" prop to be displayed.';
    }
    setSlider(activeTab);
  }, [activeTab]);

  return (
    <div className="tabs" ref={tabRef}>
      <ol>
        {props.children.map((child) => {
          const { label, dataTestid } = child.props;
          if (label) {
            return (
              <li className={'tab-list-item' + ((activeTab === label) ? ' tab-list-active' : '')}
                data-testid={dataTestid}
                key={label}
                onClick={tabOnClick}>
                {label}
              </li>
            );
          }
        })}
      </ol>
      <div className="tab-slider" />
      <div className="tab-panel">
        {props.children.map((child) => {
          if (child.props.label === activeTab) {
            return child;
          }
        })}
      </div>
    </div>
  );
};

Tabs.displayName = 'Tabs';
Tabs.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  initialTabSelection: PropTypes.string,
  onTabChange: PropTypes.func,
};

Tabs.defaultProps = {
  onTabChange: () => {},
};

export default Tabs;
