import React from 'react';
import PropTypes from 'prop-types';

import './Tabs.css';

const Tab = (props) => {
  return <div className="tab-content">{props.children}</div>;
};

Tab.propTypes = {
  children: PropTypes.node
};
export default Tab;
