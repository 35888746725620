import React from 'react';
import PropTypes from 'prop-types';
import '../../common/ValidationErrorList.common.css';
import compareError from '../../common/util/compareError';
import toPairs from 'lodash/toPairs';

const ValidationErrorList = (props) => {
  return (
    <ul className="validation-error-list">
      {toPairs(props.errors)
        .sort(compareError)
        .map(([errorMessage]) => (
          <li
            className="error-state-true"
            data-e2e="error-message"
            key={errorMessage}
          >{errorMessage}</li>
        ))}
    </ul>
  );
};

ValidationErrorList.displayName = 'Validation Error List';

ValidationErrorList.propTypes = {
  errors: PropTypes.objectOf(PropTypes.bool).isRequired,
};

export default ValidationErrorList;
