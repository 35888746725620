import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import infoIcon from './info-icon.svg';
import { ESCAPE_KEY_CODE } from '../../common/constants';

import './ToolTip.css';

const ToolTip = (props) => {

  let timeout;
  const [ visible, setVisible ] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (props.activateEvent === 'click') {
      document.addEventListener('keydown', handleKeydown);
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('keydown', handleKeydown);
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);

  const handleKeydown = (event) => {
    if (event.keyCode === ESCAPE_KEY_CODE) {
      event.stopPropagation();
      hideToolTip();
    }
  };

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      hideToolTip();
    }
  };

  const showToolTip = () => {
    if (props.delay === 0) {
      setVisible(true);
    } else {
      timeout = setTimeout(() => {
        setVisible(true);
      }, props.delay);
    }
  };

  const hideToolTip = () => {
    clearInterval(timeout);
    setVisible(false);
  };

  let toolTipComponent;

  if (props.activateEvent === 'hover') {
    toolTipComponent = <div
      className="tooltip-container"
      data-testid="tooltip"
      onMouseEnter={showToolTip}
      onMouseLeave={hideToolTip}
    >
      <img src={infoIcon}/>
      {visible && (<p className={`tooltip-tiptext ${props.position}`}>
        { ReactHtmlParser(props.tipText) }
      </p>)}
    </div>;
  } else {
    toolTipComponent = <div
      className="tooltip-container"
      data-testid="tooltip"
      onClick={showToolTip}
      ref={tooltipRef}
    >
      <img src={infoIcon}/>
      {visible && (
        <p className={`tooltip-tiptext ${props.position}`}>
          { ReactHtmlParser(props.tipText) }
        </p>
      )}
    </div>;
  }

  return toolTipComponent;
};

ToolTip.displayName = 'Tool Tip';
ToolTip.propTypes = {
  activateEvent: PropTypes.oneOf(['hover', 'click']),
  delay: PropTypes.number.isRequired,
  position: PropTypes.oneOf(['right', 'bottom']).isRequired,
  tipText: PropTypes.string.isRequired
};
ToolTip.defaultProps = {
  activateEvent: 'click',
  delay: 400,
  position: 'right',
};

export default ToolTip;
